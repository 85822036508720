import fetch from 'services/request';
import storage from 'utils/sessionStorage';
import { IApiCheckOutBody } from './types/shipping';
export const SHOPPING_HEADER_API: { shop_id: string } = { shop_id: storage.shopId.get() };
export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
};

const getAllBySalon = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/product/all',
  });
};

const getProductDetail = (productId: string) => {
  return fetch({
    method: 'get',
    url: `/api/v1/product/detail/${productId}`,
  });
};

const getConfigShipping = (shopId: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/product/config-shipping',
    shopId,
  });
};

const checkOutShopping = (shopId: string, body: IApiCheckOutBody) => {
  return fetch({
    method: 'post',
    url: '/api/v1/product/checkOut',
    shopId,
    body,
  });
};

const getTransactionShopping = (shopId: string, id: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/product/transactionDetail/${id}`,
    shopId,
  });
};

const shoppingApis = {
  getDemoData,
  getAllBySalon,
  getProductDetail,
  getConfigShipping,
  checkOutShopping,
  getTransactionShopping,
};

export default shoppingApis;

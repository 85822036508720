import NotFoundPage from 'NotFound';
import { Avatar, Button, QRCode, Row } from 'antd';
import DepositAlert from 'components/DepositAlert';
import HeadShopInfo from 'components/HeadShopInfo/HeadShopInfo';
import { LoadingWrapPage } from 'components/PageLoading';
import Text from 'components/Text';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { ContainerSuccess } from '../BookingSuccess';
import aptActions from '../services/actions';
import aptSelectors from '../services/selectors';
import { IRequestBooking } from '../services/types/api';
import { IAppointmentDetailInfo } from '../services/types/transaction';
import TopBar from './TopBar';
import BookingCoupon from '../v2/pages/SuccessPage/Coupon';

const BookingTransactionDetail = () => {
  const { transaction_id = '', shop_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const state = aptSelectors.transactionDetail();

  const services = shopSelectors.allServices();
  const staff = shopSelectors.staff();

  const detail = useMemo(() => {
    if (state.data?.status) return state.data?.appointment;

    const bookingRequest: IRequestBooking | null = state.data?.transaction.jsonObject ? JSON.parse(state.data?.transaction.jsonObject) : null;
    if (!bookingRequest) return null;
    // services
    const activeStaff = staff.find(o => o.id?.toString() === bookingRequest.staffId?.toString());
    const data: IAppointmentDetailInfo = {
      avatar: activeStaff?.avatar || '',
      name: activeStaff?.name || '',
      customerName: bookingRequest.customerName,
      customerPhone: bookingRequest.phone,
      strStartDate: bookingRequest.startTime,
      requestStaff: bookingRequest.requestStaff,
      // @ts-ignore
      services: bookingRequest.serviceIds.map(o => {
        const item = services.find(s => s.id.toString() === o.toString());
        if (!item) return null;
        return ({
          id: item.id,
          serviceName: item.name,
          price: item.price,
        });
      }).filter(o => !!o),
      note: bookingRequest.note || '',
    };
    return data;
  }, [state.data, staff, services]);

  useEffect(() => {
    dispatch(aptActions.getTransactionDetail.fetch(transaction_id));
  }, []);

  if (state.loading) return <LoadingWrapPage />;
  if (!state.data) return <NotFoundPage />;
  if (!state.data.status) {
    return <>
      <TopBar title='Payment Result' />
      <ContainerSuccess style={{ minHeight: 'unset' }}>
        <DepositAlert style={{ margin: '16px -16px', marginBottom: 16 }} fail >
          <span style={{ fontWeight: 'bold', fontSize: 16 }}>Deposited Fail</span>
        </DepositAlert>
        <HeadShopInfo />
        <Row style={{ marginTop: 32 }} justify={'center'}><p className='time'>{detail?.strStartDate || '--'}</p></Row>
        <div className="divider" />
        <Row justify={'space-between'}>
          <Row className="staff-group">
            <p>Customer</p>
          </Row>
          <Row align={'middle'} style={{ gap: 8 }}>
            <p className="staff-name value-section" style={{ fontWeight: 'bold' }}>{detail?.customerName || 'Unknown'}</p>
            <p className="staff-name value-section">{detail?.customerPhone || '--'}</p>
          </Row>
        </Row>
        <div className="divider" />
        <Row justify={'space-between'}>
          <Row className="staff-group">
            <p>Staff</p>
            {detail?.requestStaff && (
              <Row className="label-staff-request">
                <p >Request</p>
              </Row>
            )}
          </Row>
          <Row align={'middle'} style={{ gap: 8 }}>
            <Avatar size={25} src={detail?.avatar} >{detail?.name?.[0] || 'Any'}</Avatar>
            <p className="staff-name value-section">{detail?.name || 'Anybody'}</p>
          </Row>
        </Row>
        <div className="divider" />
        <div className="services">
          {detail?.services.map((o) => (
            <Row justify={'space-between'} className='service' key={o.id}>
              <p className="name">{o.serviceName}</p>
              <p className="price">{formatCurrency(o.price)}</p>
            </Row>
          ))}
        </div>
        <div className="divider" />
        <Row className='notes' justify={'space-between'}>
          <p className="label">Notes</p>
          <p className="msg">{detail?.note || 'None'}</p>
        </Row>
      </ContainerSuccess>
      <FooterSticky>
        <Button
          onClick={() => {
            window.location.href = location.origin + '/' + shop_id;
          }}
          type='default' size='large' style={{ flex: 1, height: 48, width: '100%', background: '#E5E5E5' }}>TRY AGAIN</Button>
      </FooterSticky>
    </>;
  }
  return <>
    <TopBar title='Payment Result' />
    <ContainerSuccess>
      <DepositAlert style={{ margin: '0 -16px', marginBottom: 16 }} success >
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>Successfully deposited {formatCurrency(state.data.transaction.amount)}</span>
      </DepositAlert>
      <div className="thank-box">
        <p className="step">Done</p>
        <p className="step-message">THANK YOU FOR BOOKING</p>
      </div>
      <Row justify={'center'}><IconSuccess /></Row>
      <Row justify={'center'}><p className='time'>{detail?.strStartDate || '--'}</p></Row>
      <div className="divider" />
      <Row align={'middle'} justify={'center'} style={{ margin: '32px 0' }}>
        <QRCode size={200} value={String(detail?.qrCode)} />
      </Row>
      <Row justify={'space-between'}>
        <Row className="staff-group">
          <p>Staff</p>
          {detail?.requestStaff && (
            <Row className="label-staff-request">
              <p >Request</p>
            </Row>
          )}
        </Row>
        <Row align={'middle'} style={{ gap: 8 }}>
          <Avatar size={25} src={detail?.avatar} >{detail?.name?.[0] || 'Any'}</Avatar>
          <p className="staff-name value-section">{detail?.name || 'Anybody'}</p>
        </Row>
      </Row>
      <div className="divider" />
      <div className="services">
        {detail?.services.map((o) => (
          <Row justify={'space-between'} className='service' key={o.id}>
            <p className="name">{o.serviceName}</p>
            <p className="price">{formatCurrency(o.price)}</p>
          </Row>
        ))}
      </div>
      <div className="divider" />
      <Row className='notes' justify={'space-between'}>
        <p className="label">Notes</p>
        <p className="msg">{detail?.note || 'None'}</p>
      </Row>
      <div className="divider" />
      <TransItem label='Trans#' value={state.data?.transaction?.tranId} />
      <TransItem label='Amount' value={formatCurrency(state.data?.transaction?.amount)} />
      <TransItem label='AuthCode' value={state.data?.transaction?.authCode} />
      <TransItem label='Last 4 Card' value={state.data?.transaction?.last4Num} />
      <Text mb={2} />
      {!!state.data?.appointment?.coupon && <BookingCoupon data={state.data?.appointment?.coupon} />}
    </ContainerSuccess>
    <FooterSticky>
      <Button
        onClick={() => {
          window.location.href = location.origin + '/' + shop_id;
        }}
        type='primary' size='large' style={{ flex: 1, height: 48 }}>BOOK ANOTHER APPOINTMENT</Button>
    </FooterSticky>
  </>;
};

export default BookingTransactionDetail;

const FooterSticky = styled.div`
  position: sticky;
  bottom: 0;
  padding: 8px 16px;
  padding-bottom: 16px;
  background:#fff;
  display: flex;
  left: 0;
  right: 0;
  z-index: 99;
`;

const TransItem = ({ label, value }: { label: string, value: string }) => {
  return (
    <Row justify={'space-between'}>
      <Row className="staff-group">
        <p style={{ fontWeight: '400', fontSize: 14 }}>{label}</p>
      </Row>
      <Row align={'middle'} style={{ gap: 8 }}>
        <p className="staff-name value-section" style={{ fontWeight: '600', fontSize: 16 }}>{value || '--'}</p>
      </Row>
    </Row>
  );
};
const IconSuccess = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <path d="M45 7.5C24.3375 7.5 7.5 24.3375 7.5 45C7.5 65.6625 24.3375 82.5 45 82.5C65.6625 82.5 82.5 65.6625 82.5 45C82.5 24.3375 65.6625 7.5 45 7.5ZM62.925 36.375L41.6625 57.6375C41.1375 58.1625 40.425 58.4625 39.675 58.4625C38.925 58.4625 38.2125 58.1625 37.6875 57.6375L27.075 47.025C25.9875 45.9375 25.9875 44.1375 27.075 43.05C28.1625 41.9625 29.9625 41.9625 31.05 43.05L39.675 51.675L58.95 32.4C60.0375 31.3125 61.8375 31.3125 62.925 32.4C64.0125 33.4875 64.0125 35.25 62.925 36.375Z" fill="#00B52B" />
  </svg>
);

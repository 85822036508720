import { styled } from 'styled-components';

const PageStyled = styled.div`
  background: #fff;
  display:flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,0.5);
    &:hover {
      background-color: rgba(0,0,0,0.6);
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #F5F5F5;
  }
`;

export const Header = styled.div`
  height: 54px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .btn-back-space {
    align-self: stretch;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0 16px;
  }
  span.title {
    color: #1D2129;
    text-align: center;
    font-family: Blinker;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.5%; /* 24.75px */
    letter-spacing: 0.36px;
  }
`;


export const Content = styled.div`
  display:flex;
  flex-direction: column;
  padding: 0 1rem;
  padding-bottom: 5vh;
  padding-top: 16px;
  min-height: 100vh;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #fff;
  margin-bottom: 16px;
  .qr-sub-title {
    color: #5A5E75;
    text-align: center;
    font-family: Blinker;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .qr-code-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    .qr-code-value {
      display: flex;
      padding: 4px 0px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 5px;
      background: #F6F7FC;
      span {
        color: #0085E7;
        text-align: center;
        font-family: Blinker;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 24px */
        letter-spacing: 0.96px;
      
      }
    } 
  }

  .coupon-discount {
    text-align: center;
    span {
      color: var(--Neutral_09, #1D2129);
      text-align: center;
      font-family: Blinker;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }


  .coupon-title {
  text-align: center;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Blinker;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }


  .coupon-expired {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    span {
      color: #5A5E75;
      text-align: center;
      font-family: Blinker;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .divider-dashed {
    border-bottom: 1px dashed #C9CDD4;
    height: 1px;
    margin: 12px 0;
  }

  .coupon-description {
    span.title {
      color: #1D2129;
      font-family: Blinker;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    ul {
      padding-left: 20px;
      li {
        color: #1D2129;
        font-family: Blinker;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }
    }
  }
`;

export const Banner = styled.div<{ url?: string }>`
  width: 100%;
  height: 30vh;
  flex-shrink: 0;
  background-size: cover;
  background-image: url(${({ url }) => url});
  border-radius: 16px;
`;

export const BannerEmpty = styled.div`
  width: 100%;
  height: 30vh;
  max-height: 200px;
  flex-shrink: 0;
  background-size: cover;
  margin-bottom: 3rem;
`;
// background-image: url(${banner});

export const QR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-qrcode {
    border: none;
  }
  .code {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px 16px;
  padding-bottom: 16px;
  position: sticky;
  bottom: 0;
  background: #fff;
  button { 
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    padding: 12px 16px;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 3.5rem;
    border-radius: 10px;
    background:  #FF8890;
    box-shadow: 5px 5px 10px 0px rgba(117, 127, 138, 0.50), -5px -5px 10px 0px rgba(255, 255, 255, 0.50);
    p {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &.outline {
      border-radius: 10px;
      border: 1px solid #D2464F;
      background: #FCEDEE;
       p {
        color: #D2464F;
       }
    }

  }
`;

export default PageStyled;

import set from 'lodash/set';
import { FC, SVGProps as RNSvgProps } from 'react';
import minus from './icon-minus';
import plus from './icon-plus';
import group from './icon-group';

export type SVGprops = RNSvgProps<any>;
// eslint-disable-next-line
const getIcon = (IconComponent: FC<SVGprops>) => (props: SVGprops) =>
  <IconComponent {...props} />;

const data = {
  minus,
  plus,
  group,
};
type IResult = {
  [key in keyof typeof data]: FC;
};
const mappingIcons = () => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    set(result, [key], getIcon(value));
  }
  return result as IResult;
};

const icons = mappingIcons();
export type typeIcon = keyof typeof icons;

interface Props extends SVGprops {
  type: typeIcon;
}
const Icon: FC<Props> = ({ type, ...props }) => {
  return icons[type](props);
};

export default Icon;

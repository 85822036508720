import moment from 'moment';

export type IDateRangeItem = {
  id: string;
  value: string;
  ddd: string;
  D: string;
  month: number;
  year: number;
};

const getRangeDates = (startDate: string, endDate: string, pattern?: string) => {
  const _startDate = moment(startDate, pattern);
  const _endDate = moment(endDate, pattern);

  const days: IDateRangeItem[] = [];
  let day = _startDate;

  while (day <= _endDate) {
    days.push({ value: day.format(), id: day.format('DD-MM-YYYY'), year: day.get('year'), month: day.get('month') + 1, D: day.format('D'), ddd: day.format('ddd') });
    day = day.clone().add(1, 'd');
  }
  return days;
};

export default getRangeDates;
import { Row } from 'antd';
import Text from 'components/Text';
import styled from 'styled-components';

const TopBar = ({ title }: { title?: string }) => {
  return (
    <TopBarStyled className='TopBarStyled' align={'middle'} justify={'center'}>
      <Text className='title'>{title}</Text>
    </TopBarStyled>
  );
};

export default TopBar;

const TopBarStyled = styled(Row)`
padding: 0 16px;
height: 48px;
gap: 8px;
background-color: #fff;
box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
position: sticky;
top: 0;
.title {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;

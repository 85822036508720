import { Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import GroupConfigure from './GroupConfigureItem';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
};
const GroupConfiguration = ({ value, onChange = () => undefined }: Props) => {
  const suggestionClick: (_value: number) => React.MouseEventHandler<HTMLButtonElement> =
    (_value) => (e) => {
      e.preventDefault();
      onChange(_value);
    };

  return (
    <div>
      <Between>
        <div>
          <Row>
            <Icon type={'group'} className="icon" />
            <Text ml={1} variant="TITLE_SECTION" className='group-text-size'>Group</Text>
          </Row>
          <Text variant='SUB_TEXT_SECTION'>Enter amount or select</Text>
        </div>
        <GroupConfigure minValue={2} maxValue={7} value={value} onChange={onChange} />
      </Between>
      <NumbersRow>
        <button onClick={suggestionClick(2)}><NumberBox value={2} selected={value === 2} /></button>
        <button onClick={suggestionClick(3)}><NumberBox value={3} selected={value === 3} /></button>
        <button onClick={suggestionClick(4)}><NumberBox value={4} selected={value === 4} /></button>
        <button onClick={suggestionClick(5)}><NumberBox value={5} selected={value === 5} /></button>
        <button onClick={suggestionClick(6)}><NumberBox value={6} selected={value === 6} /></button>
        <button onClick={suggestionClick(7)}><NumberBox value={7} selected={value === 7} /></button>
      </NumbersRow>
    </div>
  );
};

export default GroupConfiguration;

const NumbersRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  button {
    flex: 1;
  }
  margin-top: 8px;
`;

const NumberBox = ({ value, selected }: { value: number, selected?: boolean }) => {
  return (
    <NumberBoxStyled selected={selected}>
      <Text className='text group-text-size'>{value}</Text>
    </NumberBoxStyled>
  );
};
const NumberBoxStyled = styled.div<{ selected?: boolean }>`
  background-color: #E5E6EB;
  display: flex;
  padding: 4px 16px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .text {
    color: #1D2129;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 19.25px */
  }

  ${({ selected }) => selected ? `
    background-color: #86909C;
    .text {
      color: #fff;
    }

  `: ''}
`;

const Between = styled.div<{ mb?: number, px?: number }>`
  display: flex;
  justify-content: space-between;
  align-item: center;

  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
  ${({ px }) => px && `
    padding-right: ${px}px;
    padding-left: ${px}px;
  `}
`;
import styled from 'styled-components';
import { ICouponResItem } from 'types/response';
import CouponUI from './CouponUI';
type Props = {
  data: ICouponResItem[];
  activeId?: string;
  onChooseCoupon: (data: ICouponResItem) => void;
};
const CouponDiscount = ({ data, activeId, onChooseCoupon }: Props) => {
  if (!data.length) return null;

  return (
    <Wrapper>
      {data.map((o) => (<button type='button' key={o.id} onClick={() => onChooseCoupon(o)}><CouponUI active={activeId === o.id?.toString()} data={o} /></button>))}
    </Wrapper>
  );
};

export default CouponDiscount;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';
import { bookingSignal } from '../BookingPage/signal';

const BookingNote = () => {
  const onChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    bookingSignal.notes.value = e.target.value;
  };
  return (
    <Container>
      <Input.TextArea
        value={bookingSignal.notes.value || ''}
        onChange={onChangeText}
        placeholder='Note' />
    </Container>
  );
};

export default BookingNote;
const Container = styled.div`
padding: 8px 16px;
max-width: ${CONTAINER_BOOKING_WIDTH}px;
margin: 0 auto;
padding-bottom: 16px;
background: #FFF;
margin-bottom: 8px;

textarea.ant-input {
  color: #1D2129;
  height: 100px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  &::placeholder {
    color: #5A5E75;
  }
}
@media (min-width:600px)  {
  padding: 8px 0;
  padding-bottom: 16px;
  textarea.ant-input {
    font-size: 18px;
  }
}
`;
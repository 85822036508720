import CouponUI from 'features/Booking/BookingPage/components/BookingSteps/InformationStep/template/Discount/CouponUI';
import styled from 'styled-components';
import { ICouponResItem } from 'types/response';
type Props = {
  data: ICouponResItem | null;
};
const BookingCoupon = ({ data }: Props) => {
  if (!data) return null;
  return (
    <Container>
      {data.urlImage ? <div className='image-url'><img src={data.urlImage}></img></div> : <CouponUI data={data} />}
    </Container>
  );
};

export default BookingCoupon;

const Container = styled.div`
margin: 0 -16px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
.image-url {
  padding: 16px;
}
img {
  max-width: 374px;
  width: 100%;
}
@media (min-width:600px)  {
  
}
`;
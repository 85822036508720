import React from 'react';

const IconMinus = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="minus">
        <rect id="Rectangle 34624222" x="0.5" y="1" width="31" height="31" rx="4.5" fill="white" stroke="#C9CDD4" />
        <path id="-" d="M22 14.5V17.3029H10V14.5H22Z" fill="#686868" />
      </g>
    </svg>
  );
};

export default IconMinus;

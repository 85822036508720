import React from 'react';
import styled from 'styled-components';
type Props = {
  onBack: () => void;
};
const TabBar = ({ onBack }: Props) => {
  return (
    <Container>
      <button onClick={onBack} className="btn btn-back">Cancel</button>
      <span className="header-title">Ontiloo</span>
    </Container>
  );
};

export default TabBar;
const Container = styled.div`
display: flex;
height: 54px;
padding: 0 24px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
background: linear-gradient(90deg, #56B7FF 0%, #AFDDFF 100%);
.header-title {
  flex: 1 0 0;
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.btn-back {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
}

@media (min-width:600px)  {

}
`;

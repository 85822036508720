import React from 'react';
import RadioGroup, { APPOINTMENT_GROUP_VALUE } from './RadioGroup';
import GroupConfiguration from './GroupConfigure';
import styled from 'styled-components';
import { Row } from 'antd';
import Text from 'components/Text';
import { bookingSignal } from '../BookingPage/signal';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';
import { formatPhone } from 'utils/formatPhone';

const BookingCustomerInfo = () => {
  const customerInfo = bookingSignal.customerInfo.value;
  const setGroupValue = (value: number) => {
    bookingSignal.groupValue.value = value;
  };

  const onChangeTab = (tab: APPOINTMENT_GROUP_VALUE) => {
    if (tab === APPOINTMENT_GROUP_VALUE.GROUP) {
      bookingSignal.isGroup.value = true;
      bookingSignal.groupValue.value = 2;
      return;
    }
    bookingSignal.isGroup.value = false;
    bookingSignal.groupValue.value = 1;
  };

  return (
    <Container>
      <p className="section-title">Your Information</p>
      <RadioGroup
        value={bookingSignal.isGroup.value ? APPOINTMENT_GROUP_VALUE.GROUP : APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT}
        onChange={onChangeTab}
      />
      <Text mt={0.25} />
      {bookingSignal.isGroup.value && <GroupConfiguration value={bookingSignal.groupValue.value} onChange={setGroupValue} />}
      <Row justify={'space-between'} align={'middle'} className='customer-details'>
        <p className="customer-name">{customerInfo?.name || '--'}</p>
        <p className="customer-phone">{formatPhone(customerInfo?.phone || '')}</p>
      </Row>
    </Container>
  );
};

export default BookingCustomerInfo;

const Container = styled.div`
  max-width: ${CONTAINER_BOOKING_WIDTH}px;
  margin: 0 auto;
  padding: 8px 16px;
  border-bottom: 1px solid #C9CDD4;

  .customer-details {
    margin-top: 8px;
  }
  .customer-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
  }
  .customer-phone {
    color: var(--Neutral-08, #1D2129);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  @media (min-width:600px)  {
    padding: 8px 0;
    padding-bottom: 16px;
    
    .radio-box-label {
      font-size: 18px;
    }
    .group-text-size, .group-text-size.text {
      font-size: 18px;
    }

    .customer-name {
      font-size: 20px;
    }
    .customer-phone {
      font-size: 18px;
    }
  }
`;

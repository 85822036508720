
import { ISagaFunc } from 'services/actionConfigs';
import { IApiGetDemoDataParam } from './types/api';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import apis, { SHOPPING_HEADER_API } from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import { IDEMOModelResDataItem } from './types/demoData';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import storage from 'utils/sessionStorage';
import { IShoppingPaymentDetailResData } from './types/shipping';
import { shoppingUIActions } from './reducers';
import { RootState } from 'store';
import { ICartItem } from './types/cart';

const getDemoData: ISagaFunc<IApiGetDemoDataParam> = function* () {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  try {
    const resData: AxiosResponse<{ data: IDEMOModelResDataItem[] }> = yield call(apis.getDemoData);
    if (resData?.data?.data) {
      yield put(actions.getDemoData.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDemoData.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: false }));
  }
};

const getAllBySalon = function* () {
  yield delay(200);
  try {
    const resData: AxiosResponse<{ payload: any }> = yield call(apis.getAllBySalon);
    if (resData?.data?.payload) {
      yield put(actions.getAllBySalon.success(resData?.data?.payload));
    }
  } catch (error) {
    yield put(actions.getAllBySalon.fail({}));
  }
};

const getProductDetail: ISagaFunc<{ id: string, shopId: string }> = function* ({ payload }) {
  yield storage.shopId.set(payload.shopId);
  SHOPPING_HEADER_API.shop_id = payload.shopId;
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getProductDetail, payload.id);
    if (res.data.payload) {
      yield put(actions.getProductDetail.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getProductDetail.fail({}));
  }
};

const getTransactionShopping: ISagaFunc<{ id: string, shopId: string }> = function* ({ payload }) {
  yield storage.shopId.set(payload.shopId);
  SHOPPING_HEADER_API.shop_id = payload.shopId;
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: IShoppingPaymentDetailResData }> = yield call(apis.getTransactionShopping, payload.shopId, payload.id);
    if (res.data.payload) {
      yield put(actions.getTransactionShopping.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getTransactionShopping.fail({}));
  }
};

const getConfigShipping: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getConfigShipping, payload);
    if (res.data.payload) {
      yield put(actions.getConfigShipping.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getConfigShipping.fail({}));
  }
};

const checkCartLocal = function* () {
  const data: string = yield storage.cart.get();
  const carts: ICartItem[] = data ? JSON.parse(data) : [];
  if (!carts.length) return;
  yield put(shoppingUIActions.saveCartLocal(carts));
};

const storageCartLocal = function* () {
  yield delay(1000);
  const carts: ICartItem[] = yield select((state: RootState) => state.shopping.cart);

  if (!carts.length) return;

  yield storage.cart.set(JSON.stringify(carts));
};

const init: ISagaFunc<string> = function* ({ payload }) {
  storage.shopId.set(payload);
  SHOPPING_HEADER_API.shop_id = payload;
  yield delay(200);
  yield checkCartLocal();
  yield all([
    put(actions.getAllBySalon.fetch()),
  ]);
};

export default function* shoppingServiceSagas() {
  yield takeLatest(actions.getDemoData.fetch, getDemoData);
  yield takeLatest(actions.getAllBySalon.fetch, getAllBySalon);
  yield takeLatest(actions.getProductDetail.fetch, getProductDetail);
  yield takeLatest(actions.getConfigShipping.fetch, getConfigShipping);
  yield takeLatest(actions.getTransactionShopping.fetch, getTransactionShopping);
  yield takeLatest(actions.init, init);
  yield takeLatest(shoppingUIActions.addToCart, storageCartLocal);
  yield takeLatest(shoppingUIActions.onRemoveProductCart, storageCartLocal);
  yield takeLatest(shoppingUIActions.minusProductInCart, storageCartLocal);
  yield takeLatest(shoppingUIActions.plusProductInCart, storageCartLocal);
  yield takeLatest(shoppingUIActions.tryCheckoutAgain, storageCartLocal);
}

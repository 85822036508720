import { Avatar } from 'antd';
import Text from 'components/Text';
import React, { memo } from 'react';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
type Props = {
  data?: IStaffUI | null;
  anyone?: boolean;
  active?: boolean;
  onClick?: () => void;
};
const Staff = ({ data, anyone, active, onClick }: Props) => {

  if (anyone) {
    return (
      <StaffStyled className={`${active ? 'active' : ''}`} onClick={onClick}>
        <div className="box-avatar">
          <div className='ant-avatar'><AnyoneNailsIcon /></div>
          {active && <div className="box-avatar-overlay"><IconActive /></div>}
        </div>
        <Text className="name">Anyone</Text>
      </StaffStyled>
    );
  }

  if (!data) return null;

  return (
    <StaffStyled className={`${active ? 'active' : ''}`} onClick={onClick}>
      <div className="box-avatar">
        <Avatar src={data.avatar}>{data?.name?.[0] || ''}</Avatar>
        {active && <div className="box-avatar-overlay"><IconActive /></div>}
      </div>
      <Text className="name">{data.name}</Text>
    </StaffStyled>
  );
};

export default memo(Staff);

const StaffStyled = styled.button`
width: 100%;
border-radius: 13.584px;
background: #FFF;
box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
height: 15vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border: 1px solid #FFF;

&.active {
  border: 1px solid #F05326;
  background: #FFEFEB;
}

&:hover {
  background: #FFEFEB;
}

.box-avatar {
  border-radius: 60px;
  width: 56px;
  height: 56px;
  position: relative;
  z-index:1;
  overflow: hidden;
}
.box-avatar-overlay {
  position: absolute;
  inset: 0;
  z-index:99;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index:1;
  justify-content: center;
  .ant-avatar-string {
    font-size: 3rem;
  }
  margin-bottom: 8px;
}
.name {
  overflow: hidden;
  color: #1D2129;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
`;

const AnyoneNailsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52" fill="none">
    <g clipPath="url(#clip0_1002_39440)">
      <path d="M25.0285 0H28.7556C32.3007 0.5252 35.604 2.5636 37.5683 5.5744C38.6876 7.2072 39.309 9.1273 39.6158 11.0695C39.9967 14.0673 39.3207 17.2042 37.6762 19.747C35.6352 22.9892 32.016 25.181 28.1979 25.4826C26.9824 25.4631 25.7565 25.5671 24.5527 25.3591C21.9761 24.9132 19.5477 23.6496 17.7186 21.7802C15.4332 19.4649 14.1267 16.2513 14.0643 13.0065C13.9343 8.6164 16.2249 4.2315 19.9559 1.8941C21.4847 0.9035 23.2358 0.2886 25.0285 0Z" fill="#1D2129" />
      <path d="M8.09352 32.302C10.6558 29.4082 13.9227 27.1358 17.5315 25.75C19.3931 26.9356 21.4627 27.8144 23.6363 28.2161C24.4865 28.428 25.3627 28.5268 26.2402 28.519C27.1489 28.5099 28.0628 28.5528 28.9663 28.4384C31.3505 28.0718 33.7165 27.3438 35.7536 26.0243C35.9629 25.9229 36.1852 25.7045 36.4322 25.8189C41.5685 27.8339 45.9365 31.6663 48.6951 36.4334C49.96 38.4978 50.8518 40.7702 51.5408 43.0829C52.1206 45.0914 52.4066 47.1649 52.6341 49.2384C50.8427 49.6206 49.0656 50.06 47.2625 50.3863C46.177 50.5033 45.1227 50.8153 44.0346 50.9089C42.9062 50.9947 41.8064 51.2924 40.6741 51.3444C38.8086 51.49 36.9587 51.802 35.0841 51.8345C34.2729 51.9294 33.4435 51.8072 32.644 52.0009H21.1377C20.3382 51.8085 19.5075 51.9281 18.6963 51.8345C16.6943 51.7955 14.7183 51.4549 12.7241 51.3106C11.7244 51.2326 10.7442 51.0038 9.74582 50.9089C8.65642 50.8179 7.60212 50.4981 6.51532 50.3863C4.71612 50.047 2.93122 49.6375 1.14502 49.2384C1.22432 48.2049 1.42322 47.187 1.54412 46.1587C1.65202 45.3267 1.92372 44.5272 2.07192 43.703C3.16782 39.5105 5.18282 35.5286 8.09352 32.302ZM39.118 34.0687C39.1024 36.059 39.1193 38.0506 39.1102 40.0409C37.1186 40.05 35.1283 40.0344 33.1367 40.0474C33.1289 41.1706 33.1289 42.2951 33.1367 43.4183C35.1257 43.4443 37.1147 43.404 39.1024 43.4378C39.131 45.432 39.0946 47.4262 39.1206 49.4204C40.2828 49.4542 41.4476 49.4516 42.6111 49.4217C42.6293 47.512 42.6059 45.601 42.6189 43.6913C42.5929 43.4833 42.849 43.4053 43.0128 43.4248C44.8731 43.4183 46.7321 43.4378 48.5924 43.4183C48.6015 42.2938 48.6015 41.1706 48.5924 40.0474C46.6021 40.0344 44.6105 40.0487 42.6189 40.0422C42.6098 38.0506 42.6254 36.059 42.6124 34.0674C41.4476 34.0596 40.2828 34.0596 39.118 34.0687Z" fill="#1D2129" />
    </g>
    <defs>
      <clipPath id="clip0_1002_39440">
        <rect x="0.890137" width="52" height="52" rx="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.302 3.24744C23.7177 3.61112 23.7598 4.24288 23.3961 4.65852L9.39613 20.6585C9.21387 20.8668 8.95344 20.9902 8.67683 20.9994C8.40021 21.0087 8.13215 20.9028 7.93644 20.7071L1.93645 14.7071C1.54592 14.3166 1.54592 13.6834 1.93645 13.2929C2.32697 12.9024 2.96014 12.9024 3.35066 13.2929L8.59484 18.5371L21.891 3.34151C22.2546 2.92587 22.8864 2.88376 23.302 3.24744Z" fill="#F05326" />
  </svg>
);

import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetDemoDataParam } from './types/api';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');

const getCouponDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getCouponDetail');
const couponActions = {
  getDemoData,
  getCouponDetail,
};

export default couponActions;

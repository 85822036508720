import { AutoComplete, Avatar, Empty, Input, InputRef, Row } from 'antd';
import Text from 'components/Text';
import { useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
type Props = {
  onSelect: (data: IStaffUI) => void;
}
const InputSearch = ({ onSelect }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const staffs = shopSelectors.staff();

  const initialOptions = useMemo(() => {
    return staffs.map(o => {
      return ({
        value: o.id,
        name: o.name,
        keywords: [o.name?.toLocaleLowerCase()],
        label: renderLabel(o.name, o.avatar),
      });
    });
  }, [staffs]);

  const [searchText, setSearchText] = useState<string>('');

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return initialOptions.filter(o => o?.name?.toLowerCase()?.includes(searchText.toLowerCase()));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    inputRef.current?.blur();
    setSearchText('');
    const result = staffs?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
  };


  return (
    <InputSearchStyled wrap={false} align={'middle'}>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        notFoundContent={<Empty />}
        onSelect={handleSwitchCustomer}
        value={searchText}
        onChange={setSearchText}
      >
        <Input
          ref={inputRef}
          prefix={<IconSearch />}
          placeholder="Staff Name"
        />
      </AutoComplete>
    </InputSearchStyled>
  );
};

export default InputSearch;

const InputSearchStyled = styled(Row)`
  gap: 1rem;  
  .ant-select-single {
    height: unset;
  }
`;

const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M8.21939 16.6771C9.96583 16.6771 11.5966 16.1089 12.9222 15.1621L17.909 20.1489C18.1405 20.3804 18.4456 20.4961 18.7612 20.4961C19.4451 20.4961 19.9395 19.9701 19.9395 19.2967C19.9395 18.9811 19.8343 18.6865 19.6029 18.4551L14.6476 13.4893C15.6891 12.1216 16.3099 10.4277 16.3099 8.58657C16.3099 4.13628 12.6697 0.496094 8.21939 0.496094C3.75857 0.496094 0.128906 4.13628 0.128906 8.58657C0.128906 13.0369 3.75857 16.6771 8.21939 16.6771ZM8.21939 14.9306C4.73701 14.9306 1.87536 12.0584 1.87536 8.58657C1.87536 5.11472 4.73701 2.24254 8.21939 2.24254C11.6912 2.24254 14.5634 5.11472 14.5634 8.58657C14.5634 12.0584 11.6912 14.9306 8.21939 14.9306Z" fill="#1D2129" />
  </svg>
);


const renderLabel = (name: string, avatar: string) => (
  <Row align={'middle'}>
    <Avatar src={avatar} >{name?.[0] || ''}</Avatar>
    <Text ml={1} variant="CONTENT_2" color="text_3">
      {name}
    </Text>
  </Row>
);

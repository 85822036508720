import React, { useMemo } from 'react';
import styled from 'styled-components';
import Product from './Product';
import { IProductUI } from './types';
import shoppingSelectors from './services/selectors';
import { first } from 'lodash';
import { useParams } from 'react-router-dom';
import { useGoDetailProduct } from './ProductDetail';
import { useGoProducts } from '.';
import { Loading } from 'components/PageLoading';

const HotDeals = () => {
  const { shop_id = '' } = useParams();
  const productStores = shoppingSelectors.products();
  const navigateDetail = useGoDetailProduct();
  const navToList = useGoProducts();
  const loading = shoppingSelectors.getAllBySalonLoading();
  const hotDeals = useMemo(() => {
    return productStores.slice(0, 4).map(o => ({
      id: o.productId,
      image: first(o.images) || '',
      title: o.title,
      hotDeals: o.hotDeal,
      originalPrice: o.price,
      salePrice: o.percentDiscount ? (o.price - o.price * (o.percentDiscount / 100)) : 0,
      discountLabel: o.percentDiscount ? (o.percentDiscount + '% OFF') : '',
    } as IProductUI));
  }, [productStores]);

  const goDetail = (o: IProductUI) => () => navigateDetail(shop_id, o.id);

  const onPlusProduct = (o: IProductUI) => () => navigateDetail(shop_id, o.id);

  const goAllProducts = () => navToList(shop_id);

  if (loading) return <Loading small />;
  if (!hotDeals.length) return null;

  return (
    <Container>
      <div className="section-title">Shopping deals hot</div>
      <div className="products">
        {hotDeals.map(o => (
          <Product
            onClick={goDetail(o)}
            key={o.id}
            data={o}
            onPlus={onPlusProduct(o)}
          />
        ))}
      </div>
      <button type='button' onClick={goAllProducts} className='btn-view-all'>Seel all details {'>'} </button>
    </Container>
  );
};

export default HotDeals;

const Container = styled.div`
background: #1A7EC8;
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
.section-title {
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;
}

.btn-view-all {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (min-width:600px)  {
  padding: 16px 32px;
}
`;

import { createAction } from 'services/actionConfigs';
import { createAsyncAction } from 'store/actionConfigs';

const PREFIX_ACTIONS = 'shop_feature_';

const shopActions = {
  shopInfo: createAsyncAction(PREFIX_ACTIONS + 'getShopInfo'),
  staff: createAsyncAction(PREFIX_ACTIONS + 'getStaffs'),
  category: createAsyncAction(PREFIX_ACTIONS + 'category'),
  coupons: createAsyncAction(PREFIX_ACTIONS + 'coupons'),
  depositSetting: createAsyncAction(PREFIX_ACTIONS + 'depositSetting'),
  promotion: createAsyncAction(PREFIX_ACTIONS + 'promotion'),
  doInit: createAction<string>(PREFIX_ACTIONS + 'doInitShopData'),
};

export default shopActions;


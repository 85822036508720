import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import styled from 'styled-components';
import successBgMobile from '../assets/success-bg-mobile.png';
import successBgWindow from '../assets/success-bg-window.png';
import { IShoppingPaymentDetailResData } from '../services/types/shipping';
import { IProductUI } from '../types';
import Product from './Product';
import { useMemo } from 'react';
import shoppingSelectors from '../services/selectors';
import { first } from 'lodash';
import { formatCurrency } from 'utils/formatCurrency';
import { useGoProducts } from '..';
import { useParams } from 'react-router-dom';
import { formatPhone } from 'utils/formatPhone';
type Props = {
  data: IShoppingPaymentDetailResData;
};
const CheckoutSuccessPage = ({ data }: Props) => {
  const { shop_id = '' } = useParams();
  const productsStore = shoppingSelectors.products();
  const navToList = useGoProducts();
  const products = useMemo(() => {
    const _data: IProductUI[] = [];
    productsStore.map(o => {
      const item = data.products.find(p => p.productId === o.productId);
      if (!item) return;

      _data.push({
        id: item.productId,
        cartCount: item.quantity,
        title: o.title,
        image: first(o.images),
        hotDeals: o.hotDeal,
        originalPrice: o.price,
        salePrice: o.percentDiscount ? (o.price - o.price * (o.percentDiscount / 100)) : 0,
        discountLabel: o.percentDiscount ? (o.percentDiscount + '% OFF') : '',
      });
    });
    return _data;
  }, [data, productsStore]);
  const customer = data.customer;
  return (
    <Wrapper>
      <Container>
        <Content>
          <div className="banner-container">
            <img className='banner banner-mobile' src={successBgMobile} />
            <img className='banner banner-desktop' src={successBgWindow} />
            <div className="banner-content">
              <div className="icon-container">{successIcon}</div>
              <p className="banner-title">PAYMENT SUCCESSFUL</p>
            </div>
          </div>

          <Detail className='DetailContent'>
            <div className="place-order-container">
              <p className="placeholder-text">
                Products
              </p>
              <div className="items-container">
                {products.map(o => (
                  <Product key={o.id} data={o} />
                ))}
              </div>
            </div>

            <div className="dashed-line" />

            <div className="detail-content-items">
              <div className="detail-content-item">
                <span className="label">Time</span>
                <span className="value">{data.transaction.createdDate}</span>
              </div>
              <div className="detail-content-item">
                <span className="label">Delivering</span>
                <span className="value">{[customer?.address, customer?.city, customer?.state, customer?.zip].filter(o => !!o).join(', ')}</span>
              </div>
              <div className="detail-content-item">
                <span className="label">Receiver</span>
                <span className="value">{customer.name} | {formatPhone(customer.phone)}</span>
              </div>
              {/* <div className="detail-content-item">
                <span className="label">Arriving</span>
                <span className="value">Anne Sat, 07-22-2024</span>
              </div> */}
            </div>

            <div className="dashed-line" />

            <div className="detail-content-items transaction">
              <div className="detail-content-item">
                <span className="label">Tran#</span>
                <span className="value">#{data.transaction.tranId}</span>
              </div>
              <div className="detail-content-item">
                <span className="label">Timeline</span>
                <span className="value">{data.transaction.createdDate}</span>
              </div>
              <div className="detail-content-item">
                <span className="label">Card Type</span>
                <span className="value">VISA / Master</span>
              </div>
              <div className="detail-content-item">
                <span className="label">AuthCode</span>
                <span className="value">{data.transaction.authCode}</span>
              </div>
              <div className="detail-content-item">
                <span className="label">Last 4 Card</span>
                <span className="value">{data.transaction.last4Num}</span>
              </div>
            </div>

            <div className="dashed-line" />
            <div className="total-content-info">
              <span>TOTAL</span>
              <span className='value'>{formatCurrency(data.transaction.amount)}</span>
            </div>


          </Detail>

        </Content>
      </Container>
      <WrapperFooter>
        <FooterContainer style={{ maxWidth: 'unset' }}>
          <button onClick={() => navToList(shop_id)} type='button' className='btn btn-close'><span>CLOSE</span></button>
        </FooterContainer>
      </WrapperFooter>
    </Wrapper>
  );
};

export default CheckoutSuccessPage;

const Detail = styled.div`
  align-self: stretch;
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .dashed-line {
    align-self: stretch;
    border-bottom: 1px dashed #C9CDD4;
  }

  .place-order-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .placeholder-text {
      color: #5C6D8A;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .product-item:last-child {
        border-color: transparent;
      }
    }
  }

  .detail-content-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    .detail-content-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      span {
        color: #5C6D8A;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
      }
     
      span.value {
        color: #1D2129;
        text-align: right;
      }
    }
    &.transaction .detail-content-item span.value {
      font-weight: 500;
    }
  }

  .total-content-info {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
    span.value {
      text-align: right;
    }
  }
`;

const Content = styled.div`
  margin-bottom: 32px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  .banner-container {
    position: relative;
    width: 100%;
    .banner-content {
      z-index: 9;
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .banner-title {
        color: #E8FFEA;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.36px;
        text-transform: uppercase;
      }
    }
  }
  .banner {
    width: 100%;
    &.banner-mobile {
      display: block;
    }
    &.banner-desktop {
      display: none;
    }
  }
  @media (min-width:600px) {
    .banner-container .banner-content {
      .icon-container {
        transform: scale(1.5);
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
      }
      .banner-title {
        font-size: 32px;
      }
    }
    .banner {
      &.banner-mobile {
        display: none;
      }
      &.banner-desktop {
        display: block;
      }
    } 

    .DetailContent {
      padding: 0 32px;
      .place-order-container .placeholder-text {
        font-size: 16px;
      }

      .detail-content-items .detail-content-item span {
        font-size: 16px;
      }
      .total-content-info span {
        font-size: 24px;
      }
    }
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
flex: 1;
position: relative;

.scroll-view {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}
`;
const FooterContainer = styled(ContainerFooter)`
flex-direction: column;
align-items: center;
justify-content: center;
gap: 12px;

button.btn {
  width: 100%;
}

@media (min-width:600px) {
  padding-right: 32px;
  padding-left: 32px;
}
`;
const successIcon = <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" width="80" height="80" rx="40" fill="white" />
  <path d="M35.5743 53C34.8807 53 34.2217 52.7098 33.7361 52.202L23.9204 41.9362C22.9146 40.8842 22.9146 39.143 23.9204 38.091C24.9262 37.0391 26.591 37.0391 27.5969 38.091L35.5743 46.4342L53.4018 27.789C54.4077 26.737 56.0725 26.737 57.0783 27.789C58.0841 28.8409 58.0841 30.5821 57.0783 31.6341L37.4126 52.202C36.927 52.7098 36.268 53 35.5743 53Z" fill="#009A29" />
</svg>
  ;

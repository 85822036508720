import { Avatar, QRCode, Row } from 'antd';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import aptSelectors from '../services/selectors';
import TopBar from './TopBar';
import Text from 'components/Text';
import storage from 'utils/sessionStorage';

const BookingSuccess = () => {
  const detail = aptSelectors.successData();
  const staffs = shopSelectors.staff();
  const staff = useMemo(() => {
    return staffs.find(o => o.id === detail?.staffId);
  }, [staffs, detail]);

  if (!detail) return <Navigate to={'/'} replace />;
  return (
    <>
      <TopBar />
      <ContainerSuccess>
        <div className="thank-box">
          <p className="step">Done</p>
          <p className="step-message">THANK YOU FOR BOOKING</p>
        </div>
        <Row justify={'center'}><IconSuccess /></Row>
        <Row justify={'center'}><p className='time'>{detail.time}</p></Row>
        <div className="divider" />
        <Row align={'middle'} justify={'center'} style={{ margin: '32px 0' }}>
          <QRCode size={200} value={String(detail?.qrCode)} />
        </Row>
        <div className="divider" />
        <Row justify={'space-between'}>
          <Row className="staff-group">
            <p>Staff</p>
            {detail.requestStaff && (
              <Row className="label-staff-request">
                <p >Request</p>
              </Row>
            )}
          </Row>
          <Row align={'middle'} style={{ gap: 8 }}>
            <Avatar size={25} src={staff?.avatar} >{staff?.name?.[0] || 'Any'}</Avatar>
            <p className="staff-name value-section">{staff?.name || 'Anybody'}</p>
          </Row>
        </Row>
        <div className="divider" />
        <div className="services">
          {detail?.services.map((o, i) => (
            <Row justify={'space-between'} className='service' key={i}>
              <p className="name">{o.label}</p>
              <p className="price">{formatCurrency(o.price)}</p>
            </Row>
          ))}
        </div>
        <div className="divider" />
        <Row className='notes' justify={'space-between'}>
          <p className="label">Notes</p>
          <p className="msg">{detail.notes || 'None'}</p>
        </Row>
        <Text mb={2} />
        <Row>
          <button className="btn-new-booking"
            onClick={() => {
              window.location.href = location.origin + '/' + storage.shopId.get();
            }}
          ><p>BOOK ANOTHER APPOINTMENT</p></button>
        </Row>
      </ContainerSuccess>
    </>
  );
};

export default BookingSuccess;


export const ContainerSuccess = styled.div`
padding: 0 1rem;
margin-top: 1rem;
min-height: 100vh;

.thank-box {
  .step {
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .step-message {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  margin-bottom: 1rem;
}

.time {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
 
.divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px dotted #86909C;
  margin: 0.5rem 0;
}

.staff-group {
  gap: 8px;
  p {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}
.label-staff-request {
  border-radius: 5px;
  background: var(--9, #E8F3FF);
  display: inline-flex;
  padding: 1px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p {
    color: #148CF1;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.staff-name {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.services {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.service {
  .name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .price {
    color: #1D2129;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.notes {
  margin-bottom: 1rem;
   .label {
     color: #1D2129;
     font-family: Poppins;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
   }
   .msg {
      color: #7B7B7B;
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
}
  .btn-new-booking {
    display: flex;
    padding: 16px;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 2px;
    background: #F05326;
    p {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const IconSuccess = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <path d="M45 7.5C24.3375 7.5 7.5 24.3375 7.5 45C7.5 65.6625 24.3375 82.5 45 82.5C65.6625 82.5 82.5 65.6625 82.5 45C82.5 24.3375 65.6625 7.5 45 7.5ZM62.925 36.375L41.6625 57.6375C41.1375 58.1625 40.425 58.4625 39.675 58.4625C38.925 58.4625 38.2125 58.1625 37.6875 57.6375L27.075 47.025C25.9875 45.9375 25.9875 44.1375 27.075 43.05C28.1625 41.9625 29.9625 41.9625 31.05 43.05L39.675 51.675L58.95 32.4C60.0375 31.3125 61.8375 31.3125 62.925 32.4C64.0125 33.4875 64.0125 35.25 62.925 36.375Z" fill="#00B52B" />
  </svg>
);

import { Navigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';

const NotFoundPage = () => {
  return (
    <Navigate to={'/' + storage.shopId.get()} replace />
  );
};

export default NotFoundPage;

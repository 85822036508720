import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IProductUI } from '../types';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IProductUI;
  cartCount?: number;
  onPlus?: () => void;
  onMinus?: () => void;
  onToggle?: () => void;
  checked?: boolean;
  disabledCheck?: boolean;
  onRemove?: () => void;
};
const Product = ({ data, cartCount = 1, onMinus, onPlus, checked, onToggle, disabledCheck, onRemove }: Props) => {
  const price = (data.salePrice ? data.salePrice : data.originalPrice) || 0;
  return (
    <ProductStyled className="product-item">
      <div className="flex-container" style={{ alignItems: 'center' }}>
        {!disabledCheck && <Checkbox checked={checked} onClick={onToggle} />}
        <img src={data.image || ''} alt="bannerProduct" />
      </div>
      <div className="product-item-content">
        <p className="product-name">{data.title}</p>
        {(!!data.originalPrice && !!data.salePrice) && (
          <p className="product-price-container">
            <span className="price-throw-line">{formatCurrency(data.originalPrice)}</span>
            <span className="price-text">{formatCurrency(data.salePrice)}</span>
          </p>
        )}
        {(!!data.originalPrice && !data.salePrice) && (
          <p className="product-price-container">
            <span className="price-text">{formatCurrency(data.originalPrice)}</span>
          </p>
        )}

        <div className="amount-container">
          <div className="number-box-container">
            <button onClick={onMinus} className="btn-minus">{minusIcon}</button>
            <span>{cartCount}</span>
            <button onClick={onPlus} className="btn-minus">{plusIcon}</button>
          </div>
          <div className="amount-label">{formatCurrency(price * cartCount)}</div>
        </div>
      </div>
      {onRemove && <div className="flex-container" style={{ alignItems: 'center' }}>
        <button onClick={onRemove} className='btn btn-remove'>{removeIcon}</button>
      </div>}
    </ProductStyled>
  );
};

export default Product;

const ProductStyled = styled.div`
width: 100%;
.flex-container {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
&.product-item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid #F2F3F5;

  img {
    width: 80px;
    height: 80px;
  }

  .product-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    .product-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .product-price-container {
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .price-throw-line {
        text-decoration: line-through;
      }

    }

  }

  .amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .number-box-container {
      display: flex;
      height: 30px;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #E5E6EB;
      background: #FFF;
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
      }
      button {
        display: flex;
        width: 45px;
        height: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        &:active {
          background: #eee;
        }
        &.btn-minus {
          border-radius: 2px 0px 0px 2px;
        }
        &.btn-plus {
          border-radius: 0px 2px 2px 0px;
        }

      }
    }

    .amount-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }



  @media (min-width:600px) {
    .product-item-content > p.product-name {
      font-size: 16px;
    }
    .product-item-content > p.product-price-container {
      .price-throw-line, .price-text {
        font-size: 16px;
      }
    }
    .product-item-content > div > div.number-box-container {
      height: 40px;
      button {
        width: 50px;
        height: 40px;
        svg {
          transform: scale(1.5);
        }
      }
    }

    .product-item-content > div > div.amount-label {
      font-size: 16px;
    }
  }

}
.btn.btn-remove {
display: flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #FDECEF;
&:active {
  border-radius: 4px;
  background: #FFABB5;
  box-shadow: 0px 0px 0px 3px rgba(218, 7, 45, 0.20);
}
}

`;


const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="8" height="2" viewBox="0 0 8 2" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 1.5H0V0.5H8V1.5Z" fill="#5C6D8A" />
</svg>;
const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 4.5H0V3.5H8V4.5Z" fill="#5C6D8A" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3.5 8L3.5 -4.37114e-08L4.5 0L4.5 8L3.5 8Z" fill="#5C6D8A" />
</svg>;

const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M18.0953 24L18.9197 7.86365H4.08661L4.91096 24H18.0953ZM10 1.89496L4.70435 0L4 1.89496L19 7L19.7043 5.10504L14.6466 3.54991L15.2101 2.03374L10.5635 0.37774L10 1.89496ZM8.03956 10.4348L6.40234 10.5204L7.00234 21.9131L8.6406 21.8275L8.03956 10.4348ZM12.4389 10.4693H10.8006V21.8786H12.4389V10.4693ZM15.1999 10.4348L16.8371 10.5204L16.2371 21.9131L14.5989 21.8275L15.1999 10.4348Z" fill="#DA072D" />
</svg>;
import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['shop'];

const getCurrentState = (state: RootState): MyState => state['shop'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const shopSelectors = {
  info: () => selector('shopInfo') as MyState['shopInfo'],
  staff: () => selector('staffs') as MyState['staffs'],
  categories: () => selector('categories') as MyState['categories'],
  couponData: () => selector('couponData') as MyState['couponData'],
  allServices: () => selector('allServices') as MyState['allServices'],
  depositSetting: () => selector('depositSetting') as MyState['depositSetting'],
  promotion: () => selector('promotion') as MyState['promotion'],
};
export default shopSelectors;

import { Input, Row } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { bookingTabBarRef } from '../BookingTabBar';
type Props = {
  value?: string;
  onChangeText: (val: string) => void;
}
const InputSearch = ({ value, onChangeText }: Props) => {
  const [text, setText] = useState(value);
  const _debounce = useCallback(debounce((value: string) => onChangeText(value), 500), []);
  const handleChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
    _debounce(e.target.value);
  };


  return (
    <InputSearchStyled wrap={false} align={'middle'}>
      <Input
        onFocus={() => setTimeout(() => {
          bookingTabBarRef.current?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
        }, 500)}
        prefix={<IconSearch />}
        allowClear value={text} onChange={handleChangeText}
        placeholder="Staff Name"
      />
    </InputSearchStyled>
  );
};

export default InputSearch;

const InputSearchStyled = styled(Row)`
  gap: 1rem;  
  // position: sticky; 
  // top: 60px;
  // margin: 0 -16px;
  // padding: 0 16px;
  background: #fff;
  .ant-select-single {
    height: unset;
  }
`;

const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.0905 19.181C12.8369 19.181 14.4676 18.6128 15.7933 17.666L19.3062 21.1938C19.5376 21.4253 19.8427 21.541 20.1583 21.541C20.8422 21.541 21.3367 21.015 21.3367 20.3417C21.3367 20.026 21.2315 19.7315 21 19.5L17.5187 15.9932C18.5602 14.6255 19.181 12.9316 19.181 11.0905C19.181 6.64019 15.5408 3 11.0905 3C6.62967 3 3 6.64019 3 11.0905C3 15.5408 6.62967 19.181 11.0905 19.181ZM11.0905 17.4345C7.6081 17.4345 4.74645 14.5623 4.74645 11.0905C4.74645 7.61862 7.6081 4.74645 11.0905 4.74645C14.5623 4.74645 17.4345 7.61862 17.4345 11.0905C17.4345 14.5623 14.5623 17.4345 11.0905 17.4345Z" fill="#EF505F" />
  </svg>
);

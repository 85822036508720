import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetDemoDataParam } from './types/api';
import { IShoppingPaymentDetailResData } from './types/shipping';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');

const getAllBySalon = createAsyncAction(PREFIX_ACTIONS + 'getAllBySalon');
const getProductDetail = createAsyncAction<{ shopId: string, id: string }>(PREFIX_ACTIONS + 'getProductDetail');
const getConfigShipping = createAsyncAction<string>(PREFIX_ACTIONS + 'getConfigShipping');
const getTransactionShopping = createAsyncAction<{ shopId: string, id: string }, IShoppingPaymentDetailResData>(PREFIX_ACTIONS + 'getTransactionShopping');
const init = createAction<string>(PREFIX_ACTIONS + 'init');

const shoppingActions = {
  getDemoData,
  getAllBySalon,
  getProductDetail,
  getConfigShipping,
  getTransactionShopping,
  init,
};

export default shoppingActions;

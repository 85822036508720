import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import shoppingActions from './services/actions';

function ShoppingLayout() {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();

  useEffect(() => {
    dispatch(shoppingActions.init(shop_id));
  }, []);

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: '#1A7EC8' } }}>
      <Outlet />
    </ConfigProvider>
  );
}

export default ShoppingLayout;

import { computed } from '@preact/signals-react';
import { Avatar, Col, Row } from 'antd';
import { AxiosResponse } from 'axios';
import { pageLoading } from 'components/PageLoading';
import Text from 'components/Text';
import { BOOKING_STEP, bookingSignal } from 'features/Booking/BookingPage/signal';
import aptActions from 'features/Booking/services/actions';
import aptApis from 'features/Booking/services/apis';
import { IRequestBooking } from 'features/Booking/services/types/api';
import { IAptSuccessData } from 'features/Booking/services/types/appointment';
import { find, sumBy } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IBookingAptResData } from 'types/response';
import { IServiceUI } from 'types/service';
import storage from 'utils/sessionStorage';
import TopBar from '../../TopBar';
import CouponUI from '../InformationStep/template/Discount/CouponUI';
import StepCount from '../StepCount';
import { ANYONE_NAILS_ID } from '../helper';
import InputNotes from './InputNotes';
import RadioGroup, { APPOINTMENT_GROUP_VALUE } from './RadioGroup';
import Service from './Service';
import { formatCurrency } from 'utils/formatCurrency';
import beltGif from './belt.gif';
import { IDepositPaymentResData } from 'types/deposit';

const ReviewStep = () => {
  const dispatch = useAppDispatch();
  const staffs = shopSelectors.staff();
  const allServices = shopSelectors.allServices();
  const couponData = shopSelectors.couponData();
  const depositSetting = shopSelectors.depositSetting();

  const navigate = useNavigate();
  const staff = computed(() => {
    return staffs.find(o => o.id === bookingSignal.staffId.value);
  });
  const services = computed(() => {
    const sers: IServiceUI[] = [];
    bookingSignal.serviceIds?.value?.map(o => {
      const item = find(allServices, s => s.id === o);
      if (item) sers.push(item);
    });
    return sers;
  }).value;

  const coupon = computed(() => {
    return couponData.find(o => o.id?.toString() === bookingSignal.customerInfo.value?.coupon);
  }).value;


  useEffect(() => {
    bookingSignal.totalDuration.value = sumBy(services, o => o.duration);
  }, [services]);

  const mappingPayload = () => {
    const values = bookingSignal.getValues();

    const { customerInfo, notes, serviceIds, staffId, isRequestStaff } = values;

    const startTime = values.time?.format('MM/DD/YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM/DD/YYYY HH:mm') || '';
    const totalPrice = sumBy(services, o => o.price);

    const depositAmount = depositSetting?.depositType === 'PERCENT' ? ((totalPrice * depositSetting?.depositValue) / 100) : depositSetting?.depositValue;

    const payload: IRequestBooking = {
      customerId: customerInfo?.id ? +customerInfo?.id : null,
      customerName: customerInfo?.name || '',
      bookingType: 1,
      serviceIds: serviceIds.map(o => +o),
      staffId: +(staffId || ''),
      totalPeople: 1,
      startTime,
      endTime,
      note: notes || '',
      isAnybody: values.staffId === ANYONE_NAILS_ID ? 1 : 0,
      apiKey: storage.shopId.get(),
      couponCode: customerInfo?.coupon || '',
      requestStaff: isRequestStaff,
      id: null,
      phone: customerInfo?.phone || '',
    };
    return ({ values, payload, totalPrice, depositAmount: depositAmount || 0 });
  };

  const handlePayment = async () => {
    const { payload, depositAmount } = mappingPayload();
    pageLoading.value = true;
    try {
      const apt: AxiosResponse<{ payload: IDepositPaymentResData }> = await aptApis.depositBooking(depositAmount, payload);
      if (apt.data.payload) {
        window.location.replace(apt.data.payload.information);
      }else {
        pageLoading.value = false;
      }
    } catch (error) {
      pageLoading.value = false;
     }
  };

  const handleBook = async () => {
    const { values, payload } = mappingPayload();
    pageLoading.value = true;
    try {
      const apt: AxiosResponse<{ payload: IBookingAptResData }> = await aptApis.bookAppointment(payload);
      if (apt.data.payload) {
        const data: IAptSuccessData = {
          notes: values.notes || '',
          requestStaff: values.isRequestStaff,
          services: services.filter(o => values.serviceIds.includes(o.id)).map(o => ({ label: o.name, price: o.price })),
          staffId: values.staffId || '',
          time: values.time?.format('ddd, MMM D, YYYY HH:mm A') || '',
          qrCode: apt.data.payload.qrCode,
        };
        dispatch(aptActions.setSuccessData(data));
        navigate('success');
      }
    } catch (error) { }
    finally {
      pageLoading.value = false;
    }
  };

  return (
    <>
      <TopBar onBack={bookingSignal.back} onBook={handleBook} onPayment={depositSetting ? handlePayment : undefined} />
      {depositSetting ? <DepositAlert>
        <div className="box">
          <img src={beltGif} className='belt' />
          <span>Deposit <b style={{ fontSize: 22, fontWeight: 'bolder' }}>{depositSetting.depositType === 'PERCENT' ? `${depositSetting.depositValue}%` : formatCurrency(depositSetting.depositValue)}</b> in advance to confirm appointment</span>
        </div>
      </DepositAlert> : null}
      <StepCount />
      <Wrapper>
        <RadioGroup
          value={bookingSignal.isGroup.value ? APPOINTMENT_GROUP_VALUE.GROUP : APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT}
          onChange={tab => {
            bookingSignal.isBackToReview.value = true;
            bookingSignal.isGroup.value = tab === APPOINTMENT_GROUP_VALUE.GROUP;
          }}
        />
        <Text mb={0.5} />
        <InputNotes />
        <Text mb={0.5} />
        <div>
          <Row className='group-staff' align={'middle'}>
            <p className="label-section">Date and Time</p>
            <button className="action" onClick={() => {
              bookingSignal.isBackToReview.value = true;
              bookingSignal.stepActive.value = BOOKING_STEP.TIME_STEP;
            }}><IconReload /></button>
          </Row>
          <p className="value-section">{bookingSignal.time.value?.format('ddd, MMM D, YYYY HH:mm A')}</p>
        </div>
        <Text mb={0.5} />
        <Row justify={'space-between'}>
          <Col span={14}>
            <Row className='group-staff' align={'middle'}>
              <p className="label-section">Your Information</p>
              <button className="action" onClick={() => {
                bookingSignal.isBackToReview.value = true;
                bookingSignal.stepActive.value = BOOKING_STEP.INFORMATION_STEP;
              }}><IconEdit /></button>
            </Row>
            <p className="value-section">{bookingSignal.customerInfo.value?.name + ' - ' + bookingSignal.customerInfo.value?.phone}</p>
          </Col>
          <Col flex={1}>
            <Row className='group-staff' align={'middle'}>
              <p className="label-section">Staff</p>
              {bookingSignal.isRequestStaff.value && <Row className="label-staff-request">
                <p >Request</p>
              </Row>}
              <button className="action" onClick={() => {
                bookingSignal.isBackToReview.value = true;
                bookingSignal.stepActive.value = BOOKING_STEP.DATE_AND_STAFF_STEP;
              }}><IconReload /></button>
            </Row>
            <Row align={'middle'}>
              <Avatar size={20} src={staff.value?.avatar} >{staff.value?.name?.[0] || 'Any'}</Avatar>
              <p className="staff-name value-section">{staff.value?.name || 'Anybody'}</p>
            </Row>
          </Col>
        </Row>
        <Text mb={0.25} />
        <Text mb={0.5} />
        <div>
          <Row className='group-staff' align={'middle'}>
            <p className="label-section">Services</p>
            <button className="action" onClick={() => {
              bookingSignal.isBackToReview.value = true;
              bookingSignal.stepActive.value = BOOKING_STEP.SERVICE_STEP;
            }}><IconReload /></button>
          </Row>
          <Services gutter={[8, 8]}>
            {services.map(o => (
              <Col span={8} key={o.id}>
                <Service name={o.name} price={o.price} />
              </Col>
            ))}
          </Services>
        </div>

        {coupon && <Row align={'middle'} justify={'center'}>
          <CouponUI data={coupon} />
        </Row>}

      </Wrapper>
    </>
  );
};

export default ReviewStep;

const DepositAlert = styled.div`
  padding: 16px;
  .box {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  .belt {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
    border-radius: 5px;
    background: #FF7D00;
    box-shadow: 0px 0px 20px -4px #FF7D00;

    span {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 1rem;
  
  .label-section {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .staff-name, .value-section {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }
  .staff-name {
    margin-left: 4px;
  }
  button.action {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .group-staff {
    gap: 8px;
  }
  .label-staff-request {
    border-radius: 5px;
    background: var(--9, #E8F3FF);
    display: inline-flex;
    padding: 1px 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    p {
      color: #148CF1;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const Services = styled(Row)`
  display: flex;
`;

const IconReload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.69865 6.69538C7.81713 5.57691 9.19824 4.8867 10.6429 4.62205L10.2825 2.65479C8.45142 2.99023 6.69901 3.8666 5.28444 5.28117C1.57446 8.99115 1.57446 15.0062 5.28444 18.7162L3.57733 20.4233H8.40576V15.5949L6.69865 17.302C3.76972 14.3731 3.76972 9.62431 6.69865 6.69538ZM20.4248 3.57583H15.5964V8.40426L17.3035 6.69715C20.2324 9.62609 20.2324 14.3748 17.3035 17.3038C16.185 18.4222 14.8039 19.1124 13.3592 19.3771L13.7196 21.3444C15.5507 21.0089 17.3031 20.1325 18.7177 18.718C22.4277 15.008 22.4277 8.99292 18.7177 5.28294L20.4248 3.57583Z" fill="#F05326" />
  </svg>
);

const IconEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16V20C21 20.7956 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7956 23 18 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V6C1 5.20435 1.31607 4.44129 1.87868 3.87868C2.44129 3.31607 3.20435 3 4 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H4Z" fill="#F05326" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0929 1.29289C17.4834 0.902369 18.1166 0.902369 18.5071 1.29289L22.7071 5.49289C23.0962 5.88197 23.0978 6.51228 22.7108 6.90339L13.2108 16.5034C13.0338 16.6823 12.7958 16.7878 12.5444 16.799L8.0444 16.999C7.76018 17.0116 7.48404 16.9026 7.28511 16.6992C7.08618 16.4958 6.98329 16.2174 7.00222 15.9335L7.30222 11.4335C7.31833 11.1918 7.42162 10.9642 7.5929 10.7929L17.0929 1.29289ZM17.8 3.41421L9.27279 11.9414L9.07213 14.9514L12.0645 14.8184L20.5895 6.20369L17.8 3.41421Z" fill="#F05326" />
  </svg>
);

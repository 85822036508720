import React from 'react';
import { Helmet } from 'react-helmet-async';
type Props = {
  title: string;
  description?: string;
};
const Meta = ({ description, title }: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
    </Helmet>
  );
};

export default Meta;

import { Col, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import Text from 'components/Text';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import aptApis from 'features/Booking/services/apis';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FORMAT_TIME } from 'utils/format';
import StepCount from '../StepCount';
import { ANYONE_NAILS_ID } from '../helper';
import Time from './Time';
import TopBar from '../../TopBar';

const TimeStep = () => {
  const activeDate = bookingSignal.date.value;
  const [loading, setLoading] = useState<boolean>(false);
  const [slots, setSlots] = useState<string[]>([]);

  const handleChooseDate = (timeStr: string) => () => {
    const time = moment(timeStr, FORMAT_TIME);
    const newDateValue = activeDate?.clone().set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });
    bookingSignal.time.value = newDateValue || null;
    bookingSignal.next();
  };

  const fetchData = async () => {
    setLoading(true);
    const staffId = bookingSignal.staffId.value === ANYONE_NAILS_ID ? '' : bookingSignal.staffId.value;
    try {
      const res: AxiosResponse<{ payload: { timeSlots: string[] } }> = await aptApis.getTimeSlot(activeDate?.format('YYYY-MM-DD') || '', staffId || '');
      const timeSlots = res?.data?.payload?.timeSlots;
      if (timeSlots) setSlots(timeSlots);
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeDate, bookingSignal.staffId.value]);

  const TimeSlots = useCallback(() => {
    if (loading) return <Row align={'middle'} justify={'center'} style={{ flex: 1 }}><Spin spinning /></Row>;
    const morningList = slots.filter(o => o.endsWith('AM'));
    const afternoonList = slots.filter(o => o.endsWith('PM'));
    return (
      <>
        {morningList.length > 0 && <>
          <div className="label">Morning</div>
          <Times gutter={[8, 8]}>
            {morningList.map(o => (
              <Col key={o} span={6}>
                <Time data={o}
                  active={bookingSignal.time.value?.format(FORMAT_TIME) === o}
                  onClick={handleChooseDate(o)} />
              </Col>
            ))}
          </Times>
        </>}
        <Text mb={1} />
        {afternoonList.length > 0 && <>
          <div className="label">Afternoon</div>
          <Times gutter={[8, 8]}>
            {afternoonList.map(o => (
              <Col key={o} span={6}>
                <Time data={o}
                  active={bookingSignal.time.value?.format(FORMAT_TIME) === o}
                  onClick={handleChooseDate(o)} />
              </Col>
            ))}
          </Times>
        </>}
      </>
    );
  }, [slots, loading]);

  const handleNext = () => {
    if (!bookingSignal.time.value) return;
    bookingSignal.next();
  };

  return (
    <>
      <TopBar onBack={bookingSignal.back} onNext={handleNext} />
      <StepCount />
      <Wrapper>
        <TimeSlots />
      </Wrapper>
    </>
  );
};

export default TimeStep;

const Wrapper = styled.div`
  padding: 0 1rem;
`;
const Times = styled(Row)`
  .label {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }
`;

import { Form, FormInstance, Row } from 'antd';
import CurrencyInput from 'components/CurrencyInput';
import React from 'react';
import styled from 'styled-components';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  maxValue: number;
  minValue: number;
  form: FormInstance<{ amount: number }>

};
const BookingPartialPayment = ({ maxValue, minValue, form }: Props) => {
  return (
    <Container form={form}>
      <Row align={'middle'} justify={'space-between'}>
        <p className="section-title">partial</p>
        <p className="section-subtitle">Enter amount to charge</p>
      </Row>
      <Form.Item name={'amount'} rules={[{ required: true }, {
        validator(rule, value) {
          if (value < minValue) return Promise.reject(
            new Error(`The minimum value is ${formatCurrency(minValue)}`)
          );
          if (value > maxValue) return Promise.reject(
            new Error(`The maximum value is ${formatCurrency(maxValue)}`)
          );

          return Promise.resolve();
        },
      }]}>
        <CurrencyInput className='currency-input' />
      </Form.Item>
    </Container >
  );
};

export default BookingPartialPayment;
const Container = styled(Form)`
padding: 8px 16px;
max-width: ${CONTAINER_BOOKING_WIDTH}px;
margin: 0 auto;
padding-bottom: 16px;
background: #FFF;
margin-bottom: 8px;
.section-title {
  margin-bottom: 8px;
}
.section-subtitle {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.currency-input.ant-input {
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (min-width:600px)  {
  padding: 8px 0;
  padding-bottom: 16px;
  .section-subtitle {
    font-size: 16px;
  }
}
`;
import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import { BOOKING_STEP, bookingSignal } from '../../signal';
import DateAndStaffStep from './DateAndStaffStep';
import InformationStep from './InformationStep';
import ReviewStep from './ReviewStep';
import ServiceStep from './ServiceStep';
import TimeStep from './TimeStep';

const items: TabsProps['items'] = [
  {
    key: BOOKING_STEP.INFORMATION_STEP,
    tabKey: BOOKING_STEP.INFORMATION_STEP,
    label: 'Tab 1',
    children: <InformationStep />,
  },
  {
    key: BOOKING_STEP.SERVICE_STEP,
    tabKey: BOOKING_STEP.SERVICE_STEP,
    label: 'Tab 2',
    children: <ServiceStep />,
  },
  {
    key: BOOKING_STEP.DATE_AND_STAFF_STEP,
    tabKey: BOOKING_STEP.DATE_AND_STAFF_STEP,
    label: 'Tab 3',
    children: <DateAndStaffStep />,
  },
  {
    key: BOOKING_STEP.TIME_STEP,
    tabKey: BOOKING_STEP.TIME_STEP,
    label: 'Tab 4',
    children: <TimeStep />,
  },
  {
    key: BOOKING_STEP.REVIEW_STEP,
    tabKey: BOOKING_STEP.REVIEW_STEP,
    label: 'Tab 5',
    children: <ReviewStep />,
  },
];

const BookingSteps = () => {
  return (
    <BookingStepsStyled>
      <Tabs
        activeKey={bookingSignal.stepActive.value}
        items={items}
        animated
      />
    </BookingStepsStyled>
  );
};

export default BookingSteps;

const BookingStepsStyled = styled.div`
  min-height: calc(100vh - 50px);
  .ant-tabs-nav {
    display: none;
  }
  .ant-tabs .ant-tabs-tabpane {
    padding: 0;
  }
`;

import aptSagas from 'features/Booking/services/sagas';
import couponServiceSagas from 'features/Coupon/services/sagas';
import shoppingServiceSagas from 'features/ShoppingDeals/services/sagas';
import { all } from 'redux-saga/effects';
import shopSagas from 'services/shop/sagas';
export default function* rootSaga() {
  yield all([
    shopSagas(),
    aptSagas(),
    shoppingServiceSagas(),
    couponServiceSagas(),
  ]);
}

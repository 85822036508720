import { Drawer, Row } from 'antd';
import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import shoppingSelectors from './services/selectors';
import { useParams } from 'react-router-dom';
import { useGoCustomerInfo } from './CustomerInfo';
import { IProductUI } from './types';
import { ICartItem } from './services/types/cart';
import { find, first, sumBy } from 'lodash';
import Product from './CheckOut/Product';
import { useAppDispatch } from 'store/hooks';
import { shoppingUIActions } from './services/reducers';
type Ref = {
  open: () => void;
};
export const useCartRef = () => useRef<Ref>(null);

const CartDrawer = forwardRef<Ref>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const carts = shoppingSelectors.cart();
  const { shop_id = '' } = useParams();
  const goCustomerInfo = useGoCustomerInfo();
  const productsStore = shoppingSelectors.products();
  const cart = shoppingSelectors.cart();

  const products = useMemo(() => {
    const data: IProductUI[] = [];
    productsStore.map(o => {
      const itemCart = find(cart, (c: ICartItem) => c.productId === o.productId);
      if (!itemCart) return;
      data.push({
        id: o.productId,
        image: first(o.images) || '',
        title: o.title,
        cartCount: itemCart.amount,
        hotDeals: o.hotDeal,
        originalPrice: o.price,
        salePrice: o.percentDiscount ? (o.price - o.price * (o.percentDiscount / 100)) : undefined,
        discountLabel: o.percentDiscount ? (o.percentDiscount + '% OFF') : '',
      });
    });
    return data;
  }, [productsStore, cart]);

  const subTotal = useMemo(() => sumBy(products, o => ((o.cartCount || 0) * (o.salePrice ? o.salePrice : (o.originalPrice || 0)))), [products]);

  const onPlusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.plusProductInCart(o.id));
  const onMinusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.minusProductInCart(o.id));
  const onToggleProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.toggleCheckItem(o.id));
  const onRemoveProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.onRemoveProductCart(o.id));


  const onBuyNow = () => {
    if (!carts.length) return;
    goCustomerInfo(shop_id);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => showDrawer(),
  }));

  return (
    <Drawer classNames={{ body: 'ant-drawer-body-custom' }} closable={false} title="Your Cart" onClose={onClose} open={open}>
      <Container>
        <div className="place-order-container">
          <p className="placeholder-text">
            Place your order
          </p>
          <div className="items-container">
            {products.map(o => (
              <Product
                key={o.id} data={o}
                onPlus={onPlusProduct(o)}
                onMinus={onMinusProduct(o)}
                onToggle={onToggleProduct(o)}
                onRemove={onRemoveProduct(o)}
                cartCount={cart.find(s => s.productId === o.id)?.amount || 0}
                disabledCheck
              />
            ))}
          </div>
        </div>
      </Container>
      <WrapperFooter >
        <FooterContainer style={{ maxWidth: 'unset' }}>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <div className="partial-text">Total</div>
            <div className="partial-value">{formatCurrency(subTotal)}</div>
          </Row>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%', gap: '16px' }}>
            <button onClick={onClose} type='button' className='btn btn-outline'><span>Close</span></button>
            <button onClick={onBuyNow} disabled={subTotal <= 0} type='button' className='btn btn-submit'><span>BUY NOW</span></button>
          </Row>
        </FooterContainer>
      </WrapperFooter>
    </Drawer>
  );
});
CartDrawer.displayName = 'CartDrawer';
export default CartDrawer;

const Container = styled.div`
  background: #fff;
  flex: 1;
  padding: 16px;
  .place-order-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .placeholder-text {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .product-item:last-child {
        border-color: transparent;
      }
    }
  }
`;

const FooterContainer = styled(ContainerFooter)`
flex-direction: column;
align-items: center;
justify-content: center;
gap: 12px;

button.btn {
  width: 100%;
}

.partial-text {
  color: #5C6D8A;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.partial-value {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (min-width:600px) {
  .partial-text {
    font-size: 18px;
  }

  .partial-value {
    font-size: 24px;
  }
}
`;

import React from 'react';

function IconGroup() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.94312 9.45691C11.866 9.45691 12.8723 6.6052 12.8723 5.07634C12.8723 3.27634 11.558 1.81177 9.94312 1.81177C8.32769 1.81177 7.01312 3.27634 7.01312 5.07634C7.01312 6.60491 8.01941 9.45691 9.94312 9.45691ZM9.94312 2.95462C10.9297 2.95462 11.7294 3.90434 11.7294 5.07634C11.7294 6.24777 10.9297 8.31405 9.94312 8.31405C8.95598 8.31405 8.15627 6.24777 8.15627 5.07634C8.15627 3.90434 8.95598 2.95462 9.94312 2.95462Z" fill="#1D2129" />
      <path d="M12.8857 11.4551C12.6872 11.3129 12.4532 11.2491 12.2189 11.2491C12.006 11.2491 11.7926 11.3131 11.6052 11.4326C11.0994 11.7551 10.5249 11.9274 9.94287 11.9274C9.36116 11.9274 8.7863 11.7583 8.28058 11.4357C8.09287 11.3163 7.87973 11.258 7.66658 11.258C7.43258 11.258 7.19858 11.33 7.00001 11.4726C5.52716 12.5306 4.64716 15.1266 4.64716 17.0586C4.64716 17.6897 5.15887 18.1666 5.79001 18.1666H14.0966C14.7277 18.1666 15.2394 17.6897 15.2394 17.0586C15.2389 15.1266 14.3589 12.5131 12.8857 11.4551ZM5.78944 17.0237C5.78944 15.3094 6.53601 13.178 7.66601 12.3666C8.33544 12.7923 9.11258 13.038 9.94258 13.038C10.7729 13.038 11.5503 12.7926 12.2189 12.3666C13.3489 13.178 14.096 15.3094 14.096 17.0237H5.78944Z" fill="#1D2129" />
      <path d="M8.83427 13.5403C8.66113 13.4706 8.49056 13.3823 8.32827 13.2789C8.2297 13.216 8.10284 13.2194 8.00798 13.2877C7.13256 13.9172 6.7417 14.8374 6.7417 16.2714C6.7417 16.4294 6.8697 16.5572 7.02741 16.5572C7.18541 16.5572 7.31313 16.4294 7.31313 16.2714C7.31313 15.1112 7.57884 14.3786 8.19484 13.8649C8.3337 13.9429 8.47599 14.012 8.62056 14.0703C8.76741 14.1289 8.9337 14.0586 8.99256 13.912C9.05141 13.7654 8.98084 13.5994 8.83427 13.5403Z" fill="#1D2129" />
      <path d="M9.94259 13.7561C9.84688 13.7561 9.75231 13.7509 9.65859 13.7423C9.50145 13.7269 9.36231 13.8435 9.34773 14.0006C9.33316 14.1578 9.44888 14.2969 9.60602 14.3115C9.71716 14.3218 9.82916 14.3275 9.94259 14.3275C10.1003 14.3275 10.2283 14.1998 10.2283 14.0418C10.2283 13.8838 10.1003 13.7561 9.94259 13.7561Z" fill="#1D2129" />
      <path d="M16.5334 11.6609C16.3706 11.5666 16.1554 11.5651 15.9852 11.6454C15.9106 11.6806 15.6297 11.7526 15.4789 11.7911C15.2883 11.8409 15.1234 11.8806 15.0077 11.9246C14.7123 12.036 14.5632 12.3606 14.6743 12.656C14.7852 12.9511 15.1143 13.0911 15.4103 12.9811C15.4686 12.9591 15.6326 12.8989 15.7646 12.8654C15.9186 12.8251 16.0512 12.7551 16.1652 12.7217C17.0592 13.4343 17.7454 15.3097 17.9192 16.4526H16.3066C15.9912 16.4526 15.7352 16.7086 15.7352 17.024C15.7352 17.3394 15.9912 17.5954 16.3066 17.5954H18.5334C18.8489 17.5954 19.1049 17.41 19.1049 17.0943C19.1049 15.4603 18.1032 12.5743 16.5334 11.6609Z" fill="#1D2129" />
      <path d="M4.77571 12.6624C4.88657 12.3669 4.73686 12.0355 4.44143 11.9247C4.40514 11.9109 4.38086 11.8884 4.31629 11.8375C4.246 11.7829 4.15 11.6995 4.01686 11.6301C3.84343 11.5398 3.63571 11.5275 3.46628 11.6261C1.89686 12.5395 0.895142 15.4604 0.895142 17.0944C0.895142 17.4098 1.15114 17.5952 1.46657 17.5952H2.59228C2.90771 17.5952 3.16371 17.3392 3.16371 17.0238C3.16371 16.7084 2.90771 16.4524 2.59228 16.4524H2.08057C2.24943 15.3095 2.902 13.5358 3.75914 12.7841C3.834 12.8338 3.92514 12.9547 4.03971 12.9975C4.33571 13.1089 4.66457 12.9581 4.77571 12.6624Z" fill="#1D2129" />
      <path d="M5.6303 10.3132C5.68716 10.3203 5.75087 10.3243 5.81916 10.3243C6.30516 10.3243 7.04458 10.1186 7.56316 9.34087C7.7383 9.0783 7.66716 8.72344 7.40458 8.54858C7.1423 8.37373 6.78744 8.44487 6.6123 8.70715C6.2683 9.22344 5.80858 9.1843 5.76887 9.17887C5.7383 9.17373 5.70716 9.17144 5.67601 9.17144C5.2363 9.17144 4.46116 7.63001 4.46116 6.50515C4.46116 5.6503 5.00601 4.95487 5.67601 4.95487C5.99144 4.95487 6.24744 4.69887 6.24744 4.38344C6.24744 4.06801 5.99144 3.81201 5.67601 3.81201C4.37601 3.81201 3.3183 5.0203 3.3183 6.50515C3.3183 7.83344 4.19487 10.2623 5.6303 10.3132Z" fill="#1D2129" />
      <path d="M12.4303 8.54838C12.1674 8.72324 12.0966 9.07809 12.2717 9.34067C12.79 10.119 13.5294 10.3241 14.0157 10.3241C14.0843 10.3241 14.148 10.3201 14.2049 10.313C15.64 10.2621 16.5169 7.83324 16.5169 6.50467C16.5169 5.01981 15.4591 3.81152 14.1591 3.81152C13.8434 3.81152 13.5877 4.06752 13.5877 4.38295C13.5877 4.69838 13.8434 4.95438 14.1591 4.95438C14.8289 4.95438 15.374 5.64981 15.374 6.50467C15.374 7.62981 14.5989 9.17095 14.1591 9.17095C14.128 9.17095 14.0966 9.17352 14.066 9.17838C14.0614 9.18009 13.5797 9.24181 13.2229 8.70667C13.0474 8.44438 12.6929 8.37267 12.4303 8.54838Z" fill="#1D2129" />
    </svg>
  );
}

export default IconGroup;

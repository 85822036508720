import React from 'react';
import styled from 'styled-components';
import { BOOKING_STEP, bookingSignal } from './signal';
import { customerTabRef } from './CustomerTab';
import { servicesTabRef } from './ServicesTab';
import { staffTabRef } from './StaffTab';
import { useNavigate } from 'react-router-dom';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';

const BookingFooterActions = () => {
  const navigate = useNavigate();
  const back = () => {
    let step = null;
    switch (bookingSignal.stepActive.value) {
      case BOOKING_STEP.SERVICE_STEP: {
        step = BOOKING_STEP.INFORMATION_STEP;
        break;
      }
      case BOOKING_STEP.STAFF_STEP: {
        step = BOOKING_STEP.SERVICE_STEP;
        break;
      }
      case BOOKING_STEP.TIME_STEP: {
        step = BOOKING_STEP.STAFF_STEP;
        break;
      }
      default:
        break;
    }

    if (step) bookingSignal.onChooseTab(step);
  };

  const next = () => {
    switch (bookingSignal.stepActive.value) {
      case BOOKING_STEP.INFORMATION_STEP: {
        customerTabRef.current?.submit();
        return;
      }
      case BOOKING_STEP.SERVICE_STEP: {
        servicesTabRef.current?.submit();
        return;
      }
      case BOOKING_STEP.STAFF_STEP: {
        staffTabRef.current?.submit();
        return;
      }
      case BOOKING_STEP.TIME_STEP: {
        const valid = bookingSignal.valid();
        if (valid) navigate('review');
        return;
      }
      default:
        break;
    }
  };

  return (
    <WrapperFooter>
      <ContainerFooter>
        <button disabled={bookingSignal.stepActive.value === BOOKING_STEP.INFORMATION_STEP} onClick={back} type='button' className='btn btn-back'><span>Back</span></button>
        <button onClick={next} type='button' className='btn btn-submit'><span>Next</span></button>
      </ContainerFooter>
    </WrapperFooter>
  );
};

export default BookingFooterActions;

export const WrapperFooter = styled.div`
  border-top: 1px solid #E5E6EB;
  background: #FFF;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
`;
export const ContainerFooter = styled.div`
  max-width: ${CONTAINER_BOOKING_WIDTH}px;
  flex: 1;
  display: flex;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  button.btn {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 2px;
    background: #E5E6EB;
    span {
      color: #5C6D8A;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.btn-back, &.btn-outline {
      border-radius: 2px;
      border: 1px solid #1A7EC8;
      background: #FFF;
      span {
        color: #1A7EC8;
      }
    }

    &.btn-submit {
      border-radius: 2px;
      background: #1A7EC8;
      span {
        color: #fff;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  @media (min-width:600px)  {
    button.btn span {
      font-size: 18px;
    }
  }
`;

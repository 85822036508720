import { IStaffUI } from 'types/staff';

export const ANYONE_NAILS_ID = 'ANYONE_NAILS_ID';

export const ANYONE_STAFF: IStaffUI = {
  id: ANYONE_NAILS_ID,
  avatar: '',
  extraData: null,
  name: 'Anyone Nails',
};

import { Form, Input } from 'antd';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import React from 'react';

const InputNotes = () => {
  return (
    <Form layout='vertical'>
      <Form.Item label="Appointment Notes">
        <Input.TextArea
          rows={3}
          placeholder='Please enter...'
          value={bookingSignal.notes.value || ''}
          onChange={e => {
            bookingSignal.notes.value = e.target.value;
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default InputNotes;

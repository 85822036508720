import styled from 'styled-components';
import CustomerTab, { customerTabRef } from './CustomerTab';
import ServicesTab, { servicesTabRef } from './ServicesTab';
import { BOOKING_STEP, bookingSignal} from './signal';
import StaffTab, { staffTabRef } from './StaffTab';
import TimeTab from './TimeTab';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';

const BookingSteps = () => {
  return (
    <Container>
      <DisplayTab label='Customer Information' show={bookingSignal.stepActive.value === BOOKING_STEP.INFORMATION_STEP}><CustomerTab ref={customerTabRef} /></DisplayTab>
      <DisplayTab label='Service' show={bookingSignal.stepActive.value === BOOKING_STEP.SERVICE_STEP}><ServicesTab ref={servicesTabRef} /></DisplayTab>
      <DisplayTab label='Staff' show={bookingSignal.stepActive.value === BOOKING_STEP.STAFF_STEP}><StaffTab ref={staffTabRef} /></DisplayTab>
      <DisplayTab show={bookingSignal.stepActive.value === BOOKING_STEP.TIME_STEP}><TimeTab /></DisplayTab>
    </Container>
  );
};

export default BookingSteps;

const Container = styled.div`
  min-height: 80vh;
  max-width: ${CONTAINER_BOOKING_WIDTH}px;
  margin: 0 auto;
  padding: 0 1rem;
  padding-bottom: 16px;
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  .section-title {
    padding: 12px 0;
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  @media (min-width:600px)  {
    padding: 0;
    .section-title {
      font-size: 24px;
    }
  }
`;
type DisplayTabProps = {
  show?: boolean;
  children?: any;
  label?: string;
};
const DisplayTab = ({ show, children, label }: DisplayTabProps) => <div className={`${show ? 'show' : 'hide'}`}>
  {label && <p className='section-title'>{label}</p>}
  {children}
</div>;
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  name: string;
  price: number;
};
const Service = ({ name, price }: Props) => {
  return (
    <ServiceStyled>
      <Text className="name">{name}</Text>
      <Text className="price">{formatCurrency(price)}</Text>
    </ServiceStyled>
  );
};

export default Service;

const ServiceStyled = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 8px;
gap: 4px;
flex: 1 0 0;
border-radius: 5px;
border: 1px solid var(--5, #CECECE);
background: #FFF;

  .name {
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

import React from 'react';
import banner from '../../assets/reviewBanner.png';
import bell from '../../assets/bell.gif';
import { Image } from 'antd';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  depositAmount?: number;
};
const ReviewBanner = ({ depositAmount }: Props) => {
  return (
    <Container>
      <Image className='banner' width={'100%'} preview={false} src={banner} />
      <div className="shape" />
      <div className="content">
        <img src={bell} className='bell-icon' />
        {depositAmount ? <>
          <p className="content-text">A minimum deposit of <span>{formatCurrency(depositAmount)}</span> in advance  </p>
          <p className="content-text">to confirm appointment</p>
        </> :
          <>
            <p style={{ marginTop: 8 }} className="content-text">Please check your appointment information</p>
            <p className="content-text">and confirm that</p>
          </>
        }
      </div>
    </Container>
  );
};

export default ReviewBanner;

const Container = styled.div`
margin-bottom: 8px;
position: relative;
overflow: hidden;
.ant-image-img.banner {
  height: 169px;
}
.content {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bell-icon {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
  .content-text {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    span {
      font-size: 20px;
    }
  }
}

.shape {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  height: 268px;
  width: 268px;
  opacity: 0.15;
  background: #FFF;
  border-radius: 100%;
}

@media (min-width:600px)  {
  .ant-image-img.banner {
    height: 210px;
  }
  .content {
    .content-text {
       font-size: 20px;
       span {
       font-size: 32px;
       }
    }
  }
}
`;
import TopBar from 'features/Booking/TransactionDetail/TopBar';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import paymentFailIcon from '../../assets/paymentFail.png';
import paymentFailLgIcon from '../../assets/paymentFailLg.png';
import { ContainerFooter, WrapperFooter } from '../BookingPage/FooterActions';
import { Row } from 'antd';
import { ITransactionDetailInfo, JSONObjectBooking } from 'features/Booking/services/types/transaction';
import { formatCurrency } from 'utils/formatCurrency';
import { useNavigate, useParams } from 'react-router-dom';
import { bookingSignal } from '../BookingPage/signal';


type Props = {
  transaction?: ITransactionDetailInfo;
};
const FailPayment = ({ transaction }: Props) => {
  const { shop_id = '' } = useParams();
  const navigate = useNavigate();
  const bookingInfo: JSONObjectBooking | null = useMemo(() => {
    if (!transaction?.jsonObject) return null;
    return JSON.parse(transaction?.jsonObject);
  }, [transaction]);

  const onTryAgain = () => {
    if (!bookingInfo) {
      window.location.href = location.origin + '/v2/' + shop_id;
      return;
    }

    bookingSignal.retry(bookingInfo);
    navigate(`/v2/${bookingInfo.apiKey}/review`);
  };

  return (
    <>
      <Container>
        <TopBar title='Payment Failed' />
        <div className="payment-fail-container">
          <img src={paymentFailIcon} className='payment-fail-icon payment-fail-icon-sm' />
          <img src={paymentFailLgIcon} className='payment-fail-icon payment-fail-icon-lg' />
          <p className="payment-fail-title">Payment Failed!</p>
          <p className="payment-fail-subtitle">Hey, seem like there was some trouble.</p>
          <p className="payment-fail-subtitle">We are there with you. Just hold back.</p>
        </div>
      </Container>
      <WrapperFooter style={{ position: 'fixed' }}>
        <ContainerActions>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <div className="partial-text">Partial amount</div>
            <div className="partial-value">{formatCurrency(transaction?.amount)}</div>
          </Row>
          <button onClick={onTryAgain} type='button' className='btn btn-submit'><span>TRY AGAIN</span></button>
        </ContainerActions>
      </WrapperFooter>
    </>
  );
};

export default FailPayment;

const Container = styled.div`
background: #F7F8FA;

.TopBarStyled {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #E5E6EB;
}

.payment-fail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
}

.payment-fail-icon {
  height: auto;
  width: 200px;
  margin-bottom: 32px;

  &.payment-fail-icon-sm {
    display: block;
  }

  &.payment-fail-icon-lg {
    display: none;
  }
}

.payment-fail-title {
  color: #CB2634;
  text-align: center;
  font-family: Blinker;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%;
  /* 33px */
  letter-spacing: 0.48px;
  margin-bottom: 16px;
}

.payment-fail-subtitle {
  color: #1D2129;
  text-align: center;
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
  /* 22px */
  letter-spacing: 0.32px;
}

@media (min-width:600px) {
  .payment-fail-title {
    font-size: 32px;
  }

  .payment-fail-subtitle {
    font-size: 20px;
  }

  .payment-fail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
  }

  .payment-fail-icon {
    height: auto;
    width: 261px;
    margin-bottom: 32px;

    &.payment-fail-icon-sm {
      display: none;
    }

    &.payment-fail-icon-lg {
      display: block;
    }
  }
}
`;
const ContainerActions = styled(ContainerFooter)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  button.btn {
    width: 100%;
  }

  .partial-text {
    color: #5C6D8A;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .partial-value {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (min-width:600px)  {
    .partial-text {
      font-size: 18px;
    }
    .partial-value {
      font-size: 24px;
    }
  }
`;

import { Checkbox, Input, Row } from 'antd';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import { first, remove } from 'lodash';
import { memo, useEffect, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { formatCurrency } from 'utils/formatCurrency';
import StepCount from '../StepCount';
import TopBar from '../../TopBar';

const ServiceStep = () => {
  const categories = shopSelectors.categories();
  const [activeCategory, setActiveCategory] = useState<ICategoryUI>();
  useEffect(() => {
    const firstElement = first(categories);
    if (!firstElement) return;
    setActiveCategory(firstElement);
  }, [categories]);
  const serviceIds = bookingSignal.serviceIds.value;

  const handleChooseService = (service: IServiceUI) => {
    const temp = [...serviceIds];
    if (temp.includes(service.id)) {
      remove(temp, (o: string) => o === service.id);
    } else {
      temp.push(service.id);
    }
    bookingSignal.serviceIds.value = temp;
  };

  const handleNext = () => {
    if (!bookingSignal.serviceIds.value?.length) return;
    bookingSignal.next();
  };

  return (
    <>
      <TopBar onBack={bookingSignal.back} onNext={handleNext} />
      <StepCount />
      <Wrapper>
        <Row className='container'>
          <Input prefix={<IconSearch />} placeholder='Service Name' />
        </Row>
        <CategoriesStyled className='container'>
          {categories.map(o => (
            <CategoryStyled
              key={o.id}
              active={activeCategory?.id === o.id}
              onClick={() => setActiveCategory(o)}
            >
              <p>{o.name}</p>
            </CategoryStyled>
          ))}
        </CategoriesStyled>
        <ServicesStyled className='container'>
          {
            activeCategory?.services?.map(o => {
              const checked = !!serviceIds.includes(o.id);
              return (
                <ServiceStyled key={o.id} checked={checked} onClick={() => handleChooseService(o)}>
                  <Row className='image' ><img src={o.image} /></Row>
                  <div className='content'>
                    <div className='service-name'>{o.name}</div>
                    <Row justify={'space-between'}>
                      <p className='service-price'>{formatCurrency(o.price)}</p>
                      {checked ? <Checkbox checked /> : <div style={{ height: '25px', width: '25px' }} />}
                    </Row>
                  </div>
                </ServiceStyled>
              );
            })
          }
        </ServicesStyled>
      </Wrapper>
    </>
  );

};
export default memo(ServiceStep);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .container {
    padding-right:1rem;
    padding-left:1rem;
  }
`;

const ServicesStyled = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
`;
const ServiceStyled = styled.button<{ checked?: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #CECECE;
  padding: 4px 8px;
  gap: 4px;
  background: #FFF;
  .image {
    img {
      width: 48px;
      height: 48px;
      border-radius: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    flex: 1;
  }
  .service-name {
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }
  .service-price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z" fill="#8E9BA9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.487 15.9555C15.7796 15.6622 16.2544 15.6616 16.5477 15.9541L20.0717 19.469C20.365 19.7615 20.3656 20.2364 20.0731 20.5296C19.7806 20.8229 19.3057 20.8235 19.0125 20.531L15.4884 17.0161C15.1952 16.7236 15.1945 16.2488 15.487 15.9555Z" fill="#8E9BA9" />
  </svg>
);

const CategoriesStyled = styled.div`
overflow-x: auto;
display: flex;
justify-content: space-between;
flex-wrap: nowrap;
padding-bottom: 0.25rem;
padding-right: 0;
`;

const CategoryStyled = styled.button <{ active?: boolean }>`
padding: 10px 1rem;
align-items: center;
gap: 8px;
margin-right: 1rem;

display: flex;
align-items: center;
justify-content: center;
  p {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  min-width: 5rem;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

  ${({ active }) => active && `
    background: #F05326;
    p {
      color: #FFF;
    }
  `}
`;


import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { IState } from './types/reducer';

const initialState: IState = {
  detail: null,
  successBooking: null,
  transactionDetail: {
    data: null,
    loading: true,
  }
};

export const Slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getTransactionDetail.fetch, (state) => {
        state.transactionDetail.data = null;
        state.transactionDetail.loading = true;
      })
      .addCase(actions.getTransactionDetail.success, (state, { payload }) => {
        state.transactionDetail.data = payload;
        state.transactionDetail.loading = false;
      })
      .addCase(actions.getTransactionDetail.fail, (state) => {
        state.transactionDetail.loading = false;
      })
      .addCase(actions.detail.success, (state, { payload }) => {
        state.detail = payload;
      })
      .addCase(actions.setSuccessData, (state, { payload }) => {
        state.successBooking = payload;
      })

      ;
  },
});
const aptServiceReducer = Slice.reducer;
export default aptServiceReducer;

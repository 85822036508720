import { Carousel, Row } from 'antd';
import { LoadingWrapPage } from 'components/PageLoading';
import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import NotFoundPage from 'NotFound';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import hotDealIcon from '../assets/hot-deal-detail.png';
import shareIcon from '../assets/share.png';
import shoppingActions from '../services/actions';
import shoppingSelectors from '../services/selectors';
import TabBar from '../TabBar';
import ProductSEO from './SEO';
import uuid from 'utils/uuid';
import { shoppingUIActions } from '../services/reducers';
import toast from 'utils/toast';
import { useGoProducts } from '..';
import { useGoCustomerInfo } from '../CustomerInfo';
export const useGoDetailProduct = () => {
  const navigate = useNavigate();
  return (shopId: string, productId: string) => navigate(`/${shopId}/shopping/${productId}`);
};

const ProductDetailPage = () => {
  const dispatch = useAppDispatch();
  const navToList = useGoProducts();
  const [amount, setAmount] = useState(0);
  const { shop_id = '', id = '' } = useParams();
  const state = shoppingSelectors.detailState();
  const carts = shoppingSelectors.cart();

  const goCustomerInfo = useGoCustomerInfo();

  useEffect(() => {
    if (!state.data) return;
    const detail = carts.find(o => o.productId === state.data?.productId.toString());
    if (!detail) return setAmount(0);
    setAmount(detail.amount);
  }, [state.data]);

  const onBack = () => navToList(shop_id);

  const minusAmount = () => {
    const nextValue = amount - 1;
    if (nextValue < 0) return;
    setAmount(nextValue);
  };
  const plusAmount = () => setAmount(amount + 1);

  const onAddToCard = () => {
    if (!state.data) return;
    toast.success('Add success');
    onBack();
    dispatch(shoppingUIActions.addToCart({ amount: amount === 0 ? 1 : amount, productId: state.data.productId }));
  };

  useEffect(() => {
    dispatch(shoppingActions.getProductDetail.fetch({ shopId: shop_id, id }));
  }, []);

  const onBuyNow = () => {
    if (!state.data) return;
    if (carts.length === 0) {
      if (amount > 0) {
        dispatch(shoppingUIActions.addToCart({ amount, productId: state.data.productId }));
      }
    }
    goCustomerInfo(shop_id);
  };

  const banners = useMemo(() => {
    if (!state.data?.images) return [];
    return state.data.images.map(o => ({
      id: uuid(),
      url: o
    }));
  }, [state.data]);

  const priceSales = useMemo(() => {
    if (!state.data) return 0;

    const { price, percentDiscount } = state.data;

    return price - price * (percentDiscount / 100);
  }, [state.data]);

  if (state.loading) return <LoadingWrapPage />;
  if (!state.data) return <NotFoundPage />;

  const detail = state.data;

  const title = detail.title;

  return (
    <Container>
      <ProductSEO
        title={title}
        description={detail.description}
        type='article'
        name='Ontiloo'
      />
      <TabBar onBack={onBack} title={'Product Detail'} />
      <div className="content-container">
        <div className="scroll-view hide-scrollbar">
          <Content>
            <div className="carousel-container">
              <Carousel autoplay>
                {banners.map((o) => (
                  <div key={o.id}>
                    <div className="image-container">
                      <img src={o.url} alt="image-banner" className="image-banner" />
                    </div>
                  </div>
                ))}
              </Carousel>
              {detail.hotDeal && <img src={hotDealIcon} alt="" className="hot-deal" />}
            </div>
            <div className="container-detail">
              <RWebShare
                data={{
                  url: location.origin + '/' + shop_id + '/shopping/' + detail.productId,
                  title,
                }}
                onClick={() => undefined}
              >
                <button className="btn-share"><img src={shareIcon} alt='shareIcon' /></button>
              </RWebShare>
              <Detail>
                <div className="top-detail-container" style={{ opacity: detail.percentDiscount ? 1 : 0 }}>
                  <div className="sale-off-box"><span>{detail.percentDiscount}% OFF</span></div>
                  <span className="top-deal-text">Top deal</span>
                </div>
                <div className="product-title">{title}</div>
                {detail.percentDiscount ? <div className="price-container">
                  <div className="price-sale">{formatCurrency(priceSales)}</div>
                  <div className="price-origin">{formatCurrency(detail.price)}</div>
                </div> : <div className="price-container">
                  <div className="price-sale">{formatCurrency(detail.price)}</div>
                </div>}
                <div className="content-description" dangerouslySetInnerHTML={{ __html: detail.description }}>
                </div>
                <div className="input-container">
                  <button onClick={minusAmount} disabled={amount <= 0} className="btn-action btn-minus"><span>-</span></button>
                  <p className="placeholder-text">{amount}</p>
                  <button onClick={plusAmount} className="btn-action btn-add"><span>+</span></button>
                </div>
              </Detail>
            </div>
          </Content>
        </div>
      </div>
      <WrapperFooter >
        <FooterContainer style={{ maxWidth: 'unset' }}>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <div className="partial-text">Price</div>
            <div className="partial-value">{formatCurrency(priceSales * amount)}</div>
          </Row>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%', gap: '16px' }}>
            <button onClick={onAddToCard} type='button' className='btn btn-outline'><span>ADD TO CART</span></button>
            <button disabled={!amount && !carts.length} onClick={onBuyNow} type='button' className='btn btn-submit'><span>BUY NOW</span></button>
          </Row>
        </FooterContainer>
      </WrapperFooter>
    </Container>
  );
};

export default ProductDetailPage;
const Detail = styled.div`
max-width: 705px;
margin: auto;
margin-bottom: 24px;

.top-detail-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 40px;

  .sale-off-box {
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: linear-gradient(90deg, #FF7D00 0%, #E70404 100%);

    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .top-deal-text {
    color: #CB2634;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.product-title {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.price-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  .price-sale {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .price-origin {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 66.667% */
    text-decoration: line-through;
  }
}

.content-text {
  color: #1D2129;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
}

.content-description {
  color: #1D2129;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 228.571% */
}

.input-container {
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #C9CDD4;
  background: #FFF;

  .placeholder-text {
    color: #5C6D8A;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .btn-action {
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #FFF;

    &:active {
      background: #eee;
    }

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.4px;
    }

    &.btn-minus {
      border-radius: 2px 0px 0px 2px;
      border-right: 1px solid #C9CDD4;
    }

    &.btn-add {
      border-radius: 0px 2px 2px 0px;
      border-left: 1px solid #C9CDD4;
    }
  }
} 
`;
const Content = styled.div`
.container-detail {
  position: relative;
  padding: 0 16px;
}

.carousel-container {
  position: relative;

  img.hot-deal {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .slick-dots {
    &.slick-dots-bottom {
      transform: translateY(37px);
    }

    li {
      width: 6px;
      height: 6px;

      button {
        opacity: 1;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: #C9CDD4;
      }

      &.slick-active button {
        background: #1D2129;
      }
    }
  }
}

.image-container {
  background: #F7F8FA;
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .image-banner {
    width: 290px;
    height: 290px;
  }
}

.btn-share {
  display: flex;
  width: 40px;
  height: 40px;
  z-index: 99;
  padding: 4px;
  position: absolute;
  transform: translateY(-40px);
  right: 16px;
  top: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
  }
}

@media (min-width:600px) {
  .carousel-container {
    img.hot-deal {
      width: 151.391px;
      height: 151.391px;
      left: 17.303px;
      top: 17.302px;
    }
  }

  .image-container {
    .image-banner {
      width: 627.189px;
      height: 627.189px;
    }

  }


  .carousel-container {
    .slick-dots {
      &.slick-dots-bottom {
        transform: translateY(43px);
      }

      li {
        width: 10px;
        height: 10px;

        button {
          opacity: 1;
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }

      }
    }

  }

  .btn-share {
    width: 48px;
    height: 48px;
    right: 24px;
  }

  .input-container {
    .placeholder-text {
      font-size: 24px;
    }

    .btn-action span {
      font-size: 24px;
    }
  }
}
`;

const Container = styled.div`
height: 100vh;
display: flex;
flex-direction: column;

.content-container {
  flex: 1;
  position: relative;

  .scroll-view {
    position: absolute;
    inset: 0;
    overflow-y: auto;
  }
}
`;

const FooterContainer = styled(ContainerFooter)`
flex-direction: column;
align-items: center;
justify-content: center;
gap: 12px;

button.btn {
  width: 100%;
}

.partial-text {
  color: #5C6D8A;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.partial-value {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (min-width:600px) {
  .partial-text {
    font-size: 18px;
  }

  .partial-value {
    font-size: 24px;
  }
}
`;

import React from 'react';
import styled from 'styled-components';
type Props = {
  data: string;
  onClick?: () => void;
  active?: boolean;
};
const Time = ({ data, onClick, active }: Props) => {
  return (
    <TimeStyled onClick={onClick} className={`${active ? 'active' : ''}`}>
      <p className="time">{data}</p>
    </TimeStyled>
  );
};

export default Time;

const TimeStyled = styled.button`
width: 100%;
height: 37px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border: 1px solid #FFF;

border-radius: 5px;
border: 1px solid #86909C;
background: #FFF;

&.active {
  border: 1px solid #F05326;
  background: #FFEFEB;
}

&:hover {
  background: #FFEFEB;
}

.time {
  color:#1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

`;
import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  dataDemo: [],
  couponDetail: {
    loading: true,
    data: null,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getCouponDetail.fetch, (state) => {
        state.couponDetail.loading = true;
        state.couponDetail.data = null;
      })
      .addCase(actions.getCouponDetail.success, (state, { payload }) => {
        state.couponDetail.loading = false;
        state.couponDetail.data = payload;
      })
      .addCase(actions.getCouponDetail.fail, (state) => {
        state.couponDetail.loading = false;
        state.couponDetail.data = null;
      })
      .addCase(actions.getDemoData.success, (state, { payload }) => {
        state.dataDemo = payload;
      })
      ;
  },
});

export const couponUIActions = Slice.actions;
const couponServiceReducer = Slice.reducer;
export default couponServiceReducer;

import React from 'react';
import styled from 'styled-components';
import { IProductUI } from '../types';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IProductUI;
};
const Product = ({ data }: Props) => {
  const price = (data.salePrice ? data.salePrice : data.originalPrice) || 0;
  return (
    <ProductStyled className="product-item">

      <img src={data.image || ''} alt="bannerProduct" />

      <div className="product-item-content">
        <p className="product-name">{data.title}</p>
        {(!!data.originalPrice && !!data.salePrice) && (
          <p className="product-price-container">
            <span className="price-throw-line">{formatCurrency(data.originalPrice)}</span>
            <span className="price-text">{formatCurrency(data.salePrice)}</span>
          </p>
        )}
        {(!!data.originalPrice && !data.salePrice) && (
          <p className="product-price-container">
            <span className="price-text">{formatCurrency(data.originalPrice)}</span>
          </p>
        )}

        <div className="amount-container">
          <span className="amount-label" style={{ fontWeight: '400' }}>X{data.cartCount}</span>
          <span className="amount-label">{formatCurrency(price * (data.cartCount || 0))}</span>
        </div>
      </div>
    </ProductStyled>
  );
};

export default Product;

const ProductStyled = styled.div`
width: 100%;

&.product-item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid #F2F3F5;

  img {
    width: 80px;
    height: 80px;
  }

  .product-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    .product-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .product-price-container {
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        color: #5C6D8A;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .price-throw-line {
        text-decoration: line-through;
      }

    }

  }

  .amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    span.amount-label {
      color: #5C6D8A;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }



  @media (min-width:600px) {
    .product-item-content > p.product-name {
      font-size: 16px;
    }
    .product-item-content > p.product-price-container {
      .price-throw-line, .price-text {
        font-size: 16px;
      }
    }
    .product-item-content > div > div.number-box-container {
      height: 40px;
      button {
        width: 50px;
        height: 40px;
        svg {
          transform: scale(1.5);
        }
      }
    }

    .product-item-content > div > span.amount-label {
      font-size: 16px;
    }
  }

}


`;

import { Row } from 'antd';
import Text from 'components/Text';
import styled from 'styled-components';
import { BOOKING_STEP, bookingSignal } from '../signal';
import HeadShopInfo from 'components/HeadShopInfo/HeadShopInfo';
type Props = {
  onBook?: () => void;
  onPayment?: () => void;
  onNext?: () => void;
  onBack?: () => void;
  disabled?: boolean;
};
const TopBar = ({ onNext, onBack, onBook, onPayment, disabled }: Props) => {
  const BackButton = () => {
    if (bookingSignal.isBackToReview.value) {
      if (bookingSignal.stepActive.value !== BOOKING_STEP.REVIEW_STEP) {
        return (
          <button className='secondary' onClick={() => {
            bookingSignal.stepActive.value = BOOKING_STEP.REVIEW_STEP;
          }}>REVIEW</button>
        );
      }
    }

    if (bookingSignal.stepActive.value !== BOOKING_STEP.INFORMATION_STEP) {
      return <button className='secondary' onClick={onBack}>BACK</button>;
    }
    return null;
  };

  return (
    <>
      <TopBarStyled align={'middle'} justify={'space-between'}>
        <Text className='title'>BOOKING ONLINE</Text>
        <div style={{ flex: 1 }} />
        <BackButton />
        {bookingSignal.stepActive.value !== BOOKING_STEP.REVIEW_STEP ?
          <button onClick={onNext} disabled={disabled}>NEXT</button> :
          onPayment ? <button onClick={onPayment} disabled={disabled}>Next</button> : <button onClick={onBook} disabled={disabled}>BOOK</button>
        }
      </TopBarStyled>
      <HeadShopInfo />
    </>
  );
};

export default TopBar;

const TopBarStyled = styled(Row)`
padding: 0 16px;
height: 48px;
position: sticky;
top: 0;
gap: 8px;
z-index: 999;
background-color: #fff;
box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
.title {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
  button {
    border-radius: 5px;
    background: #F05326;
    display: flex;
    padding: 4px 8px;
    width: 68px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: right;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;

    &.secondary {
      border-radius: 5px;
      background: #EEE;
      color: #7B7B7B;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

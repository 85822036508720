import { Calendar as CalendarAnt, CalendarProps, Row } from 'antd';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { memo } from 'react';
import styled from 'styled-components';
import iconNext from './iconNext.png';
import iconPrev from './iconPrev.png';
const CalendarAntUI = CalendarAnt.generateCalendar<Moment>(momentGenerateConfig);

const CalendarView = () => {

  const handleChooseCalendar = (date: Moment) => {
    bookingSignal.date.value = date;
  };

  const onFullRender: CalendarProps<Moment>['fullCellRender'] = (date, info) => {
    const day = date.date();
    return (
      <DateItemStyled
        today={info.today.isSame(date, 'date')}
        selected={bookingSignal.date.value?.isSame(date, 'date')}
        onClick={() => handleChooseCalendar(date)}
      >
        <div className="box">{day}</div>
      </DateItemStyled>
    );
  };

  return (
    <div>
      <WrapperCalendar>
        <CalendarAntUI
          disabledDate={(date) => date.endOf('d').valueOf() < moment().valueOf()}
          fullscreen={false}
          fullCellRender={onFullRender}
          headerRender={({ value, onChange }) => {
            const handlePrev = () => {
              const nextValue = value.clone().subtract(1, 'month');
              if (!nextValue.isSameOrAfter(moment())) return;
              onChange(nextValue);
            };

            return (
              <HeaderCalendar align={'middle'} justify={'space-between'}>
                <ButtonArrow onClick={handlePrev}>
                  <img src={iconPrev} />
                </ButtonArrow>
                <div>
                  <p className="month">{value.format('MMMM')}</p>
                  <p className="year">{value.format('YYYY')}</p>
                </div>
                <ButtonArrow onClick={() => onChange(value.clone().add(1, 'month'))}>
                  <img src={iconNext} />
                </ButtonArrow>
              </HeaderCalendar>
            );
          }}
        />
      </WrapperCalendar>
    </div>
  );
};

export default memo(CalendarView);

const WrapperCalendar = styled.div`
.ant-picker-calendar {
  .ant-picker-panel {
    border: 0;
  }
  .ant-picker-content {
    thead {
      th {
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .ant-picker-cell-disabled {
    &::before {
      background: transparent;
    }
    .box {
      opacity: 0.2;
    }
  }
  .ant-picker-cell {
    .ant-picker-cell-inner {
      height: 30px;
        width: 30px;
      .ant-picker-calendar-date-value {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        align-self: stretch;
      }
    }
  }
  .ant-picker-content td {
    padding: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    &::before {
      border:none;
    }
  }
}

`;

const HeaderCalendar = styled(Row)`
  .month {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .year {
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ButtonArrow = styled.button`
  img {
    width: 32px;
    height: 32px;
  }
`;

const DateItemStyled = styled.button<{ selected?: boolean, today?: boolean }>`
width: 100%;
display: flex;
text-align: center;

align-items: center;
justify-content: center;
font-size: 1.75rem;
text-align: center;

font-family: Open Sans;
font-style: normal;
font-weight: 400;
line-height: normal;


-webkit-user-select: none;
/* Safari */
-ms-user-select: none;
/* IE 10 and IE 11 */
user-select: none;
/* Standard syntax */

  .box {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--4, #1D2129);
    text-align: center;

    /* Body/Body 1 - 14 regular - 21 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${({ today }) => today && `
      color: #F05326;
    `}
    ${({ selected }) => selected && `
    color: var(--fill-fill-0, #FFF);
    text-align: center;
    
    /* Title/H9 - 18 semibold */
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      border-radius: 100px;
      background: #F05326;
    `}
  }

`;
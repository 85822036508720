import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { BOOKING_STEP, bookingSignal } from '../../signal';

const numberSteps = {
  [BOOKING_STEP.INFORMATION_STEP]: 1,
  [BOOKING_STEP.SERVICE_STEP]: 2,
  [BOOKING_STEP.DATE_AND_STAFF_STEP]: 3,
  [BOOKING_STEP.TIME_STEP]: 4,
  [BOOKING_STEP.REVIEW_STEP]: 5,
};

const titleSteps = {
  [BOOKING_STEP.INFORMATION_STEP]: 'Your Information',
  [BOOKING_STEP.SERVICE_STEP]: 'Select Services',
  [BOOKING_STEP.DATE_AND_STAFF_STEP]: 'Select DATE & STAFF',
  [BOOKING_STEP.TIME_STEP]: 'Select TIME',
  [BOOKING_STEP.REVIEW_STEP]: 'REVIEW',
};

const StepCount = () => {
  return (
    <StepCountStyled>
      <Text className='step-number'>Step {numberSteps[bookingSignal.stepActive.value]} of 5</Text>
      <Text className='step-name'>{titleSteps[bookingSignal.stepActive.value]}</Text>
    </StepCountStyled>
  );
};

export default StepCount;

const StepCountStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  .step-number {
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .step-name {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
`;

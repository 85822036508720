import fetch from 'services/request';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
};

const getCouponDetail = (id: string) => {
  return fetch({
    url: `/api/v1/coupon/detail/${id}`,
    method: 'get',
  });
};

const couponApis = {
  getDemoData,
  getCouponDetail,
};

export default couponApis;

import React, { useMemo } from 'react';
import styled from 'styled-components';
import TabBar from './TabBar';
import { IProductUI } from './types';
import Product from './Product';
import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import { useNavigate, useParams } from 'react-router-dom';
import shoppingSelectors from './services/selectors';
import { first } from 'lodash';
import ProductSEO from './ProductDetail/SEO';
import { useAppDispatch } from 'store/hooks';
import { shoppingUIActions } from './services/reducers';
import shoppingActions from './services/actions';
import { useGoCustomerInfo } from './CustomerInfo';
import { Loading } from 'components/PageLoading';

export const useGoProducts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (shopId: string) => {
    dispatch(shoppingActions.init(shopId));
    navigate(`/${shopId}/shopping`);
  };
};

const ShoppingDeals = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const navigate = useNavigate();
  const productsStore = shoppingSelectors.products();
  const carts = shoppingSelectors.cart();
  const goCustomerInfo = useGoCustomerInfo();
  const loading = shoppingSelectors.getAllBySalonLoading();

  const products = useMemo(() => productsStore.map(o => ({
    id: o.productId,
    image: first(o.images) || '',
    title: o.title,
    hotDeals: o.hotDeal,
    originalPrice: o.price,
    salePrice: o.percentDiscount ? (o.price - o.price * (o.percentDiscount / 100)) : undefined,
    discountLabel: o.percentDiscount ? (o.percentDiscount + '% OFF') : '',
  })), [productsStore]);

  const onPlusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.plusProductInCart(o.id));
  const onMinusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.minusProductInCart(o.id));

  const goDetail = (o: IProductUI) => () => navigate('/' + shop_id + '/shopping/' + o.id);

  return (
    <Container>
      <ProductSEO
        title={'Ontiloo'}
        description={''}
        type='article'
        name='Ontiloo'
      />
      <TabBar title={'Shopping Hot deals'} />
      <div className="content-container-wrapper">
        {loading && <Loading small />}

        <div className="products" style={{ opacity: loading ? 0 : 1 }}>
          {products.map(o => (
            <Product
              key={o.id}
              data={o}
              onClick={goDetail(o)}
              onPlus={onPlusProduct(o)}
              onMinus={onMinusProduct(o)}
              cartCount={carts.find(s => s.productId === o.id)?.amount || 0}
            />
          ))}
        </div>
      </div>
      <WrapperFooter style={{ position: 'sticky' }}>
        <ContainerFooter>
          <button disabled={!carts.length} onClick={() => goCustomerInfo(shop_id)} type='button' className='btn btn-submit'><span>proceed to check out</span></button>
        </ContainerFooter>
      </WrapperFooter>
    </Container>
  );
};

export default ShoppingDeals;
const Container = styled.div`
display: flex;
flex-direction: column;

.content-container-wrapper {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .scroll-view {
    position: absolute;
    inset: 0;
    overflow-y: auto;
  }
  .products {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding-bottom: 24px;
  }
}

@media (min-width:600px)  {
  .content-container .products {
    padding: 24px 32px;
  }
}
`;

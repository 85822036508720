
export const NAME_REDUCER = 'shopping';

export const PREFIX_ACTIONS = 'shopping_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getAllBySalon: `loading.${NAME_REDUCER}.getAllBySalon`,
};

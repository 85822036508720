import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['appointment'];

const getCurrentState = (state: RootState): MyState => state['appointment'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const aptSelectors = {
  detail: () => selector('shopInfo') as MyState['detail'],
  successData: () => selector('successBooking') as MyState['successBooking'],
  transactionDetail: () => selector('transactionDetail') as MyState['transactionDetail'],
};
export default aptSelectors;

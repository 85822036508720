import React from 'react';
import styled from 'styled-components';
import cardHeadIcon from './assets/cart-head.png';
import shoppingSelectors from './services/selectors';
import CartDrawer, { useCartRef } from './CartDrawer';
type Props = {
  title?: string;
  disableCart?: boolean;
  onBack?: () => void;
};
const TabBar = ({ title, disableCart, onBack }: Props) => {
  const cart = shoppingSelectors.cart();
  const cartRef = useCartRef();

  return (
    <Container>
      <button onClick={onBack} className="btn btn-back">{iconBack}</button>
      <span className="header-title">{title}</span>
      {!disableCart && <button className="btn btn-cart" onClick={() => cartRef.current?.open()}>
        <img className='card-icon' src={cardHeadIcon} />
        {!!cart.length && <div className='badge'><span>{cart.length}</span></div>}
      </button>}
      <CartDrawer ref={cartRef} />
    </Container>
  );
};

export default TabBar;
const Container = styled.div`
display: flex;
height: 54px;
padding: 0 24px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
position: sticky;
top: 0;
background: #fff;
z-index: 99;

.header-title {
  flex: 1 0 0;
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%;
  position: absolute;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
}

.btn-cart {
  position: relative;
  .card-icon {
    width: 32px;
    height: 32px;
  }
  .badge {
    position: absolute;
    top: 0; right: 0;
    transform: translate(5px,-5px);
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #FFF;
    background: #F05326;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
    }
  }
}
@media (min-width:600px)  {
  .btn-cart {
    .card-icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }
  }
}
`;

const iconBack = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <g clipPath="url(#clip0_2174_3295)"> <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="#1D2129" /> </g> <defs> <clipPath id="clip0_2174_3295"> <rect width="24" height="24" fill="white" /> </clipPath> </defs> </svg>;
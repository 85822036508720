import styled from 'styled-components';
import BookingSteps from './BookingSteps';
import BookingFooterActions from './FooterActions';
import BookingPromotion from './Promotion';
import BookingSalonInfo from './SalonInfo';
import BookingTabBar, { bookingTabBarRef } from './BookingTabBar';
import { memo } from 'react';

const BookingPageV2 = () => {
  return (
    <WrapStyled>
      <div className="promotion-sticky">
        <BookingPromotion />
      </div>
      <BookingSalonInfo />
      <BookingTabBar />
      <div ref={bookingTabBarRef}></div>
      <BookingSteps />
      <BookingFooterActions />
    </WrapStyled>
  );
};

export default memo(BookingPageV2);


const WrapStyled = styled.div`
  position: relative;
`;
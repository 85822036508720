import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import shoppingActions from './services/actions';
import shoppingSelectors from './services/selectors';
import CheckoutFailPage from './CheckoutFail';
import CheckoutSuccessPage from './CheckoutSuccess';
import { LoadingWrapPage } from 'components/PageLoading';
import NotFoundPage from 'NotFound';

const ShoppingTransactionDetailPage = () => {
  const { shop_id = '', transaction_id = '' } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(shoppingActions.getTransactionShopping.fetch({ shopId: shop_id, id: transaction_id }));
  }, []);

  const state = shoppingSelectors.transaction();

  if (state.loading) return <LoadingWrapPage />;
  if (!state.data) return <NotFoundPage />;
  if (state.data.status) return <CheckoutSuccessPage data={state.data} />;
  return <CheckoutFailPage data={state.data.transaction} />;
};

export default ShoppingTransactionDetailPage;
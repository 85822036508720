import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDEMOModelResDataItem } from './types/demoData';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IProductDataItem } from './types/product';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['shopping']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['shopping']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getDemoData = () => selector('demoData') as IDEMOModelResDataItem[];

const getDemoDataLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const getAllBySalonLoading = () => selector('loading') as boolean;

const products = () => selector('products') as IProductDataItem[];
const detailState = () => selector('detail') as MyState['detail'];
const cart = () => selector('cart') as MyState['cart'];
const customerInfo = () => selector('customerInfo') as MyState['customerInfo'];
const configShipping = () => selector('configShipping') as MyState['configShipping'];
const checkedItems = () => selector('checkedItems') as MyState['checkedItems'];
const address = () => selector('address') as MyState['address'];
const transaction = () => selector('transaction') as MyState['transaction'];

const shoppingSelectors = {
  getDemoData,
  getDemoDataLoading,
  products,
  detailState,
  cart,
  customerInfo,
  configShipping,
  checkedItems,
  transaction,
  address,
  getAllBySalonLoading,
};
export default shoppingSelectors;

import { Calendar as CalendarAnt, CalendarProps, Modal, Row } from 'antd';
import { ANYONE_NAILS_ID } from 'features/Booking/BookingPage/components/BookingSteps/helper';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import getRangeDates, { IDateRangeItem } from 'utils/getRangeDates';
import arrowNext from '../../../assets/arrowNext.png';
import arrowPrev from '../../../assets/arrowPrev.png';
import { bookingSignal } from '../signal';
import iconNext from './iconNext.png';
import iconPrev from './iconPrev.png';
import { bookingTimeSlotListRef } from './TimeSlotList';
const format = 'MM-DD-YYYY';
const CalendarAntUI = CalendarAnt.generateCalendar<Moment>(momentGenerateConfig);

const BookingDatePicker = () => {
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [startDate, setStartDate] = useState(moment());

  const listDate = useMemo(() => {
    const endDate = startDate.clone().add(6, 'days');
    const dates = getRangeDates(startDate.format(format), endDate.format(format), format);
    return dates;
  }, [startDate]);

  const onNavPress = (action: 'prev' | 'next') => () => {
    if (action === 'next') {
      const nextValue = startDate.clone().add(7, 'days');
      setStartDate(nextValue);
      return;
    }
    const nextValue = startDate.clone().subtract(7, 'days');
    if (!nextValue.isSameOrAfter(moment(), 'date')) return;
    setStartDate(nextValue);
  };

  const handleChooseCalendar = (date: Moment) => {
    setVisibleCalendar(false);
    bookingSignal.date.value = date;
    bookingTimeSlotListRef.current?.fetchTimes(bookingSignal.staffId.value || ANYONE_NAILS_ID, date);
  };

  const handleChooseDate = (o: IDateRangeItem) => () => {
    const date = moment(o.value);
    bookingSignal.date.value = date;
    bookingTimeSlotListRef.current?.fetchTimes(bookingSignal.staffId.value || ANYONE_NAILS_ID, date);
  };

  return (
    <Container>
      <div className="nav-picker">
        <button type='button' className='btn btn-calendar' onClick={() => setVisibleCalendar(true)}>
          <IconCalendar />
          <div className="month-label">{bookingSignal.date.value?.format('MMMM D, YYYY') || '--'}</div>
        </button>
        <div className="actions">
          <ButtonNav onClick={onNavPress('prev')} type='button' className='btn btn-action'><img src={arrowPrev} /></ButtonNav>
          <ButtonNav onClick={onNavPress('next')} type='button' className='btn btn-action'><img src={arrowNext} /></ButtonNav>
        </div>
      </div>
      <ListDate className='ListDate'>
        {listDate.map(o => (
          <button
            key={o.id}
            onClick={handleChooseDate(o)}
            className={`btn date-box ${bookingSignal.date.value?.format('DD-MM-YYYY') === o.id ? 'active' : ''}`}
          >
            <p>{o.ddd}</p>
            <p className='date'>{o.D}</p>
          </button>))}
      </ListDate>
      <ModalDatePicker value={bookingSignal.date.value} onChooseCalendar={handleChooseCalendar} visible={visibleCalendar} onClose={() => setVisibleCalendar(false)} />
    </Container>
  );
};

export default BookingDatePicker;
type ModalDatePickerProps = {
  visible?: boolean;
  onClose?: () => void;
  value: Moment | null;
  onChooseCalendar: (date: Moment) => void;
};
const ModalDatePicker = ({ onClose, visible, value, onChooseCalendar }: ModalDatePickerProps) => {
  const onFullRender: CalendarProps<Moment>['fullCellRender'] = (date, info) => {
    const day = date.date();
    return (
      <DateItemStyled
        className='DateItemStyled'
        today={info.today.isSame(date, 'date')}
        selected={value?.isSame(date, 'date')}
        onClick={() => onChooseCalendar(date)}
      >
        <div className="box">{day}</div>
      </DateItemStyled>
    );
  };

  return (
    <Modal closeIcon={null} centered open={visible} footer={null} onCancel={onClose}>
      <WrapperCalendar>
        <CalendarAntUI
          disabledDate={(date) => date.endOf('d').valueOf() < moment().valueOf()}
          fullscreen={false}
          fullCellRender={onFullRender}
          headerRender={({ value, onChange }) => {
            const handlePrev = () => {
              const nextValue = value.clone().subtract(1, 'month');
              if (!nextValue.isSameOrAfter(moment(),'month')) return;
              onChange(nextValue);
            };

            return (
              <HeaderCalendar className='HeaderCalendar' align={'middle'} justify={'space-between'}>
                <ButtonArrow onClick={handlePrev}>
                  <img src={iconPrev} />
                </ButtonArrow>
                <div>
                  <p className="month">{value.format('MMMM')}</p>
                  <p className="year">{value.format('YYYY')}</p>
                </div>
                <ButtonArrow onClick={() => onChange(value.clone().add(1, 'month'))}>
                  <img src={iconNext} />
                </ButtonArrow>
              </HeaderCalendar>
            );
          }}
        />
      </WrapperCalendar>
    </Modal>
  );
};

const ListDate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  .date-box {
    display: flex;
    height: 62px;
    padding: 4px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid #C9CDD4;
    background: #fff;
    &.active {
      border-radius: 10px;
      border: 1px solid #1A7EC8;
      background: #DCF0FF;
    }
    p {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      &.date {
        font-weight: 600;
      }
    }
  }
`;

const ButtonNav = styled.button`
&:active {
  background: #E5E6EB;
}
&.btn-action {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
}
`;

const Container = styled.div`
.nav-picker {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .month-label {
    flex: 1;
    color: #1D2129;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.btn-calendar {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  margin-right: 32px;
}

@media (min-width:600px)  {
  .ListDate {
    .btn.date-box {
      p {
        font-size: 16px;
      }
    }
  }

  .nav-picker {
    .month-label {
      font-size: 18px;
    }
    .btn-action {
      width: 35px;
      height: 35px;
      img {
          width: 32px;
          height: 32px;
      }
    }
  }
}
`;

const IconCalendar = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / ionicons / outline / calendar-outline">
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M4.5 4.5C3.67157 4.5 3 5.17157 3 6V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V6C21 5.17157 20.3284 4.5 19.5 4.5H4.5ZM1.5 6C1.5 4.34315 2.84315 3 4.5 3H19.5C21.1569 3 22.5 4.34315 22.5 6V19.5C22.5 21.1569 21.1569 22.5 19.5 22.5H4.5C2.84315 22.5 1.5 21.1569 1.5 19.5V6Z" fill="#1D2129" />
      <path id="Vector" d="M13.875 12C14.4963 12 15 11.4963 15 10.875C15 10.2537 14.4963 9.75 13.875 9.75C13.2537 9.75 12.75 10.2537 12.75 10.875C12.75 11.4963 13.2537 12 13.875 12Z" fill="#1D2129" />
      <path id="Vector_2" d="M17.625 12C18.2463 12 18.75 11.4963 18.75 10.875C18.75 10.2537 18.2463 9.75 17.625 9.75C17.0037 9.75 16.5 10.2537 16.5 10.875C16.5 11.4963 17.0037 12 17.625 12Z" fill="#1D2129" />
      <path id="Vector_3" d="M13.875 15.75C14.4963 15.75 15 15.2463 15 14.625C15 14.0037 14.4963 13.5 13.875 13.5C13.2537 13.5 12.75 14.0037 12.75 14.625C12.75 15.2463 13.2537 15.75 13.875 15.75Z" fill="#1D2129" />
      <path id="Vector_4" d="M17.625 15.75C18.2463 15.75 18.75 15.2463 18.75 14.625C18.75 14.0037 18.2463 13.5 17.625 13.5C17.0037 13.5 16.5 14.0037 16.5 14.625C16.5 15.2463 17.0037 15.75 17.625 15.75Z" fill="#1D2129" />
      <path id="Vector_5" d="M6.375 15.75C6.99632 15.75 7.5 15.2463 7.5 14.625C7.5 14.0037 6.99632 13.5 6.375 13.5C5.75368 13.5 5.25 14.0037 5.25 14.625C5.25 15.2463 5.75368 15.75 6.375 15.75Z" fill="#1D2129" />
      <path id="Vector_6" d="M10.125 15.75C10.7463 15.75 11.25 15.2463 11.25 14.625C11.25 14.0037 10.7463 13.5 10.125 13.5C9.50368 13.5 9 14.0037 9 14.625C9 15.2463 9.50368 15.75 10.125 15.75Z" fill="#1D2129" />
      <path id="Vector_7" d="M6.375 19.5C6.99632 19.5 7.5 18.9963 7.5 18.375C7.5 17.7537 6.99632 17.25 6.375 17.25C5.75368 17.25 5.25 17.7537 5.25 18.375C5.25 18.9963 5.75368 19.5 6.375 19.5Z" fill="#1D2129" />
      <path id="Vector_8" d="M10.125 19.5C10.7463 19.5 11.25 18.9963 11.25 18.375C11.25 17.7537 10.7463 17.25 10.125 17.25C9.50368 17.25 9 17.7537 9 18.375C9 18.9963 9.50368 19.5 10.125 19.5Z" fill="#1D2129" />
      <path id="Vector_9" d="M13.875 19.5C14.4963 19.5 15 18.9963 15 18.375C15 17.7537 14.4963 17.25 13.875 17.25C13.2537 17.25 12.75 17.7537 12.75 18.375C12.75 18.9963 13.2537 19.5 13.875 19.5Z" fill="#1D2129" />
      <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M6 1.5C6.41421 1.5 6.75 1.83579 6.75 2.25V3.75C6.75 4.16421 6.41421 4.5 6 4.5C5.58579 4.5 5.25 4.16421 5.25 3.75V2.25C5.25 1.83579 5.58579 1.5 6 1.5Z" fill="#1D2129" />
      <path id="Vector (Stroke)_3" fillRule="evenodd" clipRule="evenodd" d="M18 1.5C18.4142 1.5 18.75 1.83579 18.75 2.25V3.75C18.75 4.16421 18.4142 4.5 18 4.5C17.5858 4.5 17.25 4.16421 17.25 3.75V2.25C17.25 1.83579 17.5858 1.5 18 1.5Z" fill="#1D2129" />
      <path id="Vector (Stroke)_4" fillRule="evenodd" clipRule="evenodd" d="M2.25 6.75H21.75V8.25H2.25V6.75Z" fill="#1D2129" />
    </g>
  </svg>
);


const WrapperCalendar = styled.div`
.ant-picker-calendar {
  .ant-picker-panel {
    border: 0;
  }
  .ant-picker-content {
    thead {
      th {
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .ant-picker-cell-disabled {
    &::before {
      background: transparent;
    }
    .box {
      opacity: 0.2;
    }
  }
  .ant-picker-cell {
    .ant-picker-cell-inner {
      height: 30px;
        width: 30px;
      .ant-picker-calendar-date-value {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        align-self: stretch;
      }
    }
  }
  .ant-picker-content td {
    padding: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    &::before {
      border:none;
    }
  }
}
@media (min-width:600px)  {
  .ant-picker-calendar {
    .ant-picker-content thead th {
      font-size: 18px;
    }

    .DateItemStyled {
      .box {
        height: 50px;
        width: 50px;
        font-size: 22px;
      }
    }
  }
  .HeaderCalendar {
    .month {
      font-size: 26px;
    }

    .year {
      font-size: 22px;
    }
  }
}
`;

const HeaderCalendar = styled(Row)`
  padding: 0 16px;
  .month {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .year {
    color: #7B7B7B;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ButtonArrow = styled.button`
  img {
    width: 40px;
    height: 40px;
  }
`;

const DateItemStyled = styled.button<{ selected?: boolean, today?: boolean }>`
width: 100%;
display: flex;
text-align: center;

align-items: center;
justify-content: center;
font-size: 1.75rem;
text-align: center;

font-family: Open Sans;
font-style: normal;
font-weight: 400;
line-height: normal;


-webkit-user-select: none;
/* Safari */
-ms-user-select: none;
/* IE 10 and IE 11 */
user-select: none;
/* Standard syntax */

  .box {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--4, #1D2129);
    text-align: center;

    /* Body/Body 1 - 14 regular - 21 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${({ today }) => today && `
      color: #1A7EC8;
    `}
    ${({ selected }) => selected && `
    color: var(--fill-fill-0, #FFF);
    text-align: center;
    
    /* Title/H9 - 18 semibold */
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      border-radius: 100px;
      background: #1A7EC8;
    `}
  }

`;
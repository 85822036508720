import Text from 'components/Text';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';

const HeadShopInfo = () => {
  const shopInfo = shopSelectors.info();
  if (!shopInfo) return null;

  return (
    <HeadShopInfoStyled>
      <BoxInfo>
        <Text className='name'>{shopInfo?.companyName}</Text>
        <Text className='address'>{shopInfo?.address} ${shopInfo?.city ? ', ' + shopInfo?.city : ''}</Text>
        <Text className='phone'>Phone: {formatPhone(shopInfo.phone)}</Text>
      </BoxInfo>
    </HeadShopInfoStyled>
  );
};

export default HeadShopInfo;

const HeadShopInfoStyled = styled.div`
  background-color: #EEE;
`;

const BoxInfo = styled.div`
  padding: 0.5rem 1rem;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .name {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .phone, .address {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`;
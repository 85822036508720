import styled from 'styled-components';
import BookingSteps from './components/BookingSteps';

const BookingPage = () => {
  return (
    <WrapStyled>
      <BookingSteps />
    </WrapStyled>
  );
};

export default BookingPage;


const WrapStyled = styled.div`
  min-height: 100vh;
  position: relative;
`;
import { LoadingWrapPage } from 'components/PageLoading';
import aptActions from 'features/Booking/services/actions';
import aptSelectors from 'features/Booking/services/selectors';
import NotFoundPage from 'NotFound';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import BookingSuccessPage from '../SuccessPage';
import FailPayment from './FailPayment';

const BookingTransactionDetail = () => {
  const { transaction_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const state = aptSelectors.transactionDetail();

  useEffect(() => {
    dispatch(aptActions.getTransactionDetail.fetch(transaction_id));
  }, []);

  if (state.loading) return <LoadingWrapPage />;
  if (!state.data) return <NotFoundPage />;

  if (!state.data.status) return <FailPayment transaction={state.data.transaction} />;

  return <BookingSuccessPage transaction={state.data.transaction} />;
};

export default BookingTransactionDetail;

import React from 'react';

const IconPlus = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Plus">
        <rect id="Rectangle 34624223" x="0.5" y="1" width="31" height="31" rx="4.5" fill="white" stroke="#C9CDD4" />
        <path id="+" d="M22 17.6294H17.2471V22.5H14.7529V17.6294H10V15.3706H14.7529V10.5H17.2471V15.3706H22V17.6294Z" fill="#686868" />
      </g>
    </svg>
  );
};

export default IconPlus;

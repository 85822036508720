import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'store/actionConfigs';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
import { IStaffUI } from 'types/staff';
import storage from 'utils/sessionStorage';
import actions from './actions';
import apis from './apis';
import { ICategoryResItem, ICouponResItem, IServiceResItem, IStaffResItem } from 'types/response';
import { IShopInfoResData } from 'types/shop';
import { pageLoading } from 'components/PageLoading';
import { isEmpty } from 'lodash';

const getCategories = function* () {
  try {
    const { cate, ser } = yield all({ cate: call(apis.getCategories), ser: call(apis.getServices) });

    const categoriesRes: ICategoryResItem[] = cate?.data?.payload;
    const servicesRes: IServiceResItem[] = ser?.data?.payload;
    if (categoriesRes && servicesRes) {
      const data: ICategoryUI[] = categoriesRes.map((o) => ({
        id: o.id?.toString(),
        name: o.categoryName,
        services: servicesRes.filter(s => s.catId === o.id).map(s => ({
          cateId: o.id?.toString(),
          id: s.id?.toString(),
          image: s.urlImage,
          name: s.serviceName,
          duration: s.duration,
          price: s.price,
        }) as IServiceUI),
      }));
      yield put(actions.category.success(data));
    }
  } catch (error) {

  }
};

const getStaffs = function* () {
  try {
    const res: AxiosResponse<{ payload: IStaffResItem[] }> = yield call(apis.getStaffs);
    const staffRes = res?.data?.payload;
    if (staffRes) {
      const data: IStaffUI[] = staffRes.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(actions.staff.success(data));
    }
  } catch (error) {

  }
};
const getCoupons = function* () {
  try {
    const res: AxiosResponse<{ payload: ICouponResItem[] }> = yield call(apis.getCoupons);
    const resData = res?.data?.payload || [];
    if (resData) {
      yield put(actions.coupons.success(resData));
    }
  } catch (error) { }
};

const getShopInfo = function* (shopId: string) {
  pageLoading.value = true;
  try {
    const res: AxiosResponse<{ payload: IShopInfoResData }> = yield call(apis.getShopDetail, shopId);
    const shopData = res.data.payload;
    if (shopData) {
      yield put(actions.shopInfo.success(shopData));
    }
  } catch (error) { }
  finally {
    pageLoading.value = false;
  }
};

const getSettingDeposit = function* () {
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getSettingDeposit);
    const resData = res?.data?.payload || {};
    if (!isEmpty(resData)) {
      yield put(actions.depositSetting.success(resData));
    }
  } catch (error) { }
};

const getPromotions = function* () {
  try {
    const res: AxiosResponse<{ payload: any }> = yield call(apis.getPromotions);
    const resData = res?.data?.payload || {};
    if (!isEmpty(resData)) {
      yield put(actions.promotion.success(resData));
    }
  } catch (error) { }
};

const doInitShopData: ISagaFunc<string> = function* ({ payload }) {
  yield storage.shopId.set(payload);
  yield delay(200);
  yield all([
    getCategories(),
    getStaffs(),
    getCoupons(),
    getSettingDeposit(),
    getShopInfo(payload),
    getPromotions(),
  ]);
};

export default function* shopSagas() {
  yield takeLatest(actions.doInit, doInitShopData);
}
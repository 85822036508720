import { Row } from 'antd';
import { AxiosResponse } from 'axios';
import { pageLoading } from 'components/PageLoading';
import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import { find, first, sumBy } from 'lodash';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';
import { useGoAddAddress } from '../AddAddress';
import { useGoCustomerInfo } from '../CustomerInfo';
import Meta from '../Meta';
import shoppingActions from '../services/actions';
import shoppingApis from '../services/apis';
import { shoppingUIActions } from '../services/reducers';
import shoppingSelectors from '../services/selectors';
import { ICartItem } from '../services/types/cart';
import { IApiCheckOutBody } from '../services/types/shipping';
import TabBar from '../TabBar';
import { IProductUI } from '../types';
import Product from './Product';
import toast from 'utils/toast';
import storage from 'utils/sessionStorage';
export const useGoCheckOutShopping = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (shop_id: string) => {
    dispatch(shoppingActions.getConfigShipping.fetch(shop_id));
    dispatch(shoppingUIActions.checkAddressDefault());
    navigate(`/${shop_id}/shopping/check-out`);
  };
};
const CheckOutPage = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const goCustomerInfo = useGoCustomerInfo();
  const goAddAddress = useGoAddAddress();

  const onBack = () => goCustomerInfo(shop_id);
  const productsStore = shoppingSelectors.products();
  const cart = shoppingSelectors.cart();
  const configShipping = shoppingSelectors.configShipping();
  const customerInfo = shoppingSelectors.customerInfo();
  const address = shoppingSelectors.address();

  const checkedItems = shoppingSelectors.checkedItems();

  const products = useMemo(() => {
    const data: IProductUI[] = [];
    productsStore.map(o => {
      const itemCart = find(cart, (c: ICartItem) => c.productId === o.productId);
      if (!itemCart) return;
      data.push({
        id: o.productId,
        image: first(o.images) || '',
        title: o.title,
        cartCount: itemCart.amount,
        hotDeals: o.hotDeal,
        originalPrice: o.price,
        salePrice: o.percentDiscount ? (o.price - o.price * (o.percentDiscount / 100)) : undefined,
        discountLabel: o.percentDiscount ? (o.percentDiscount + '% OFF') : '',
      });
    });
    return data;
  }, [productsStore, cart]);

  const checkedProducts = useMemo(() => products.filter(o => checkedItems.includes(o.id)), [products, checkedItems]);

  const subTotal = useMemo(() => sumBy(checkedProducts, o => ((o.cartCount || 0) * (o.salePrice ? o.salePrice : (o.originalPrice || 0)))), [products, checkedItems, checkedProducts]);

  const orderTotal = useMemo(() => {
    const feeShipping = configShipping?.feeShipping || 0;
    return sumBy([subTotal, feeShipping]);
  }, [subTotal, configShipping]);

  const onPlusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.plusProductInCart(o.id));
  const onMinusProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.minusProductInCart(o.id));
  const onToggleProduct = (o: IProductUI) => () => dispatch(shoppingUIActions.toggleCheckItem(o.id));

  const onCheckOut = async () => {
    if (!address) {
      toast.info('Please enter your address!');
      return goAddAddress(shop_id);
    }

    const body: IApiCheckOutBody = {
      customerInfo: {
        address: address?.address || '',
        city: address?.city || '',
        customerId: +(customerInfo?.id || ''),
        email: '',
        name: customerInfo?.name || '',
        phone: customerInfo?.phone || '',
        state: address?.state || '',
        zip: address?.zip || '',
      },
      optionShipping: '',
      products: checkedProducts.map(o => ({ productId: o.id, quantity: o.cartCount || 0 })),
      total: orderTotal,
    };
    pageLoading.value = true;
    try {
      const res: AxiosResponse<{ payload: any }> = await shoppingApis.checkOutShopping(shop_id, body);
      if (res.data.payload) {
        storage.cart.set('');
        window.location.replace(res.data.payload.information);
      } else {
        pageLoading.value = false;
      }
    } catch (error) {
      pageLoading.value = false;
    }
  };

  return (
    <Wrapper>
      <Meta title='Ontiloo - Proceed To Check Out' description='' />
      <TabBar onBack={onBack} disableCart title='Proceed To Check Out' />
      <Container>
        <Content >
          <div className="place-order-container">
            <p className="placeholder-text">
              Place your order
            </p>
            <div className="items-container">
              {products.map(o => (
                <Product
                  key={o.id} data={o}
                  onPlus={onPlusProduct(o)}
                  onMinus={onMinusProduct(o)}
                  onToggle={onToggleProduct(o)}
                  checked={checkedItems.includes(o.id)}
                  cartCount={cart.find(s => s.productId === o.id)?.amount || 0}
                />
              ))}
            </div>
          </div>
          <div className="divider" />
          <div className="summary-container">
            <div className="summary-item">
              <span>Total items:</span>
              <span>{formatCurrency(subTotal)}</span>
            </div>
            <div className="summary-item">
              <span>Shipping & handling:</span>
              <span>{formatCurrency(configShipping?.feeShipping)}</span>
            </div>
            <div className="summary-item">
              <span>Tax:</span>
              <span>$0.00</span>
            </div>
            <div className="summary-item total">
              <span>Order Total:</span>
              <span>{formatCurrency(orderTotal)}</span>
            </div>
          </div>
          <div className="divider" />
          <div className="delivery-container">
            <div className="delivery-title">Delivering to</div>
            {
              address ?
                <div className="address-container">
                  <p>{customerInfo?.name} | {formatPhone(customerInfo?.phone || '')}</p>
                  <p>{[address.address || '', address.city || '', address.state || '', address.zip || ''].filter(o => !!o).join(', ')}.</p>
                  <button className="btn-link" onClick={() => goAddAddress(shop_id, true)}>Change delivering address</button>
                </div>
                :
                <div className="address-container">
                  <p>{customerInfo?.name} | {formatPhone(customerInfo?.phone || '')}</p>
                  <p>No address yet.</p>
                  <button className="btn-link" onClick={() => goAddAddress(shop_id)}>Add delivering address</button>
                </div>
            }
          </div>
          {
            configShipping?.estimateTime && <>
              <div className="divider" />
              <div className="delivery-container" style={{ gap: 0 }}>
                <div className="delivery-title">Arriving</div>
                <div className="address-container">
                  <p>Guaranteed delivery within {configShipping.estimateTime}</p>
                </div>
              </div>
            </>
          }
        </Content>
      </Container>
      <WrapperFooter>
        <FooterContainer style={{ maxWidth: 'unset' }}>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <div className="partial-text">Total</div>
            <div className="partial-value">{formatCurrency(orderTotal)}</div>
          </Row>
          <button onClick={onCheckOut} disabled={orderTotal <= 0} type='button' className='btn btn-submit'><span>PAY</span></button>
        </FooterContainer>
      </WrapperFooter>
    </Wrapper>
  );
};

export default CheckOutPage;

const Content = styled.div`
  padding: 16px;
  margin-bottom: 32px;
  min-height: 100vh;

  .divider {
    background: #C9CDD4;
    align-self: stretch;
    height: 1px;
    margin: 12px -16px;
  }
  .place-order-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .placeholder-text {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .product-item:last-child {
        border-color: transparent;
      }
    }
  }

  .summary-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;
    .summary-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.total {
        span {
          color: #1D2129;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .delivery-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .delivery-title {
      align-self: stretch;
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .address-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      p {
        align-self: stretch;
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button.btn-link {
        color: #1A7EC8;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:active {
          background: #eee;
        }
      }
    }
  }


@media (min-width:600px) {
  padding: 24px 32px;
  .divider {
    margin: 12px 0;
  }

  .summary-container .summary-item {
    span {
      font-size: 16px;
    }

    &.total span {
      font-size: 18px;
    }
  }
  .delivery-container .delivery-title {
    font-size: 18px;
  }
  .delivery-container .address-container {
    p {
      font-size: 16px;
    }

    button.btn-link {
      font-size: 16px;
    }
  }

}
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
flex: 1;
position: relative;

.scroll-view {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}
`;

const FooterContainer = styled(ContainerFooter)`
flex-direction: column;
align-items: center;
justify-content: center;
gap: 12px;

button.btn {
  width: 100%;
}

.partial-text {
  color: #5C6D8A;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.partial-value {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (min-width:600px) {
  padding-right: 32px;
  padding-left: 32px;
  .partial-text {
    font-size: 18px;
  }

  .partial-value {
    font-size: 24px;
  }
}
`;

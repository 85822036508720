import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import beltGif from './belt.gif';

type Props = {
  alert?: boolean;
  success?: boolean;
  fail?: boolean;
  children?: any;
  style?: CSSProperties;
};
const DepositAlert = ({ children, success, fail, style }: Props) => {
  return (
    <DepositAlertStyled style={style} className={`${fail && 'fail'} ${success && 'success'}`}>
      <div className="box">
        <img src={beltGif} className='belt' />
        <span>{children}</span>
      </div>
    </DepositAlertStyled>
  );
};

export default DepositAlert;

const DepositAlertStyled = styled.div`
  padding: 0 16px;
  .box {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  .belt {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
    border-radius: 5px;
    background: #FF7D00;
    box-shadow: 0px 0px 20px -4px #FF7D00;

    span {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    }
  &.success {
    .box {
      background: #00B52B;
      box-shadow: 0px 6px 12px -4px rgba(223, 13, 13, 0.20);
    }
  }
  &.fail {
    .box {
      background: #F00;
      box-shadow: 0px 6px 12px -4px rgba(223, 13, 13, 0.20);
    }
  }
`;
import { Row } from 'antd';
import Text from 'components/Text';
import styled from 'styled-components';
export enum APPOINTMENT_GROUP_VALUE {
  INDIVIDUAL_APT = 'INDIVIDUAL_APT',
  GROUP = 'GROUP',
}
type Props = {
  value: APPOINTMENT_GROUP_VALUE;
  onChange: (val: APPOINTMENT_GROUP_VALUE) => void;
};
const RadioGroup = ({ value, onChange }: Props) => {
  return (
    <RadioGroupStyled align={'middle'}>
      <RadioButton
        active={APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT === value}
        label='Individual Appointment'
        onClick={() => onChange(APPOINTMENT_GROUP_VALUE.INDIVIDUAL_APT)}
      />
      <RadioButton
        active={APPOINTMENT_GROUP_VALUE.GROUP === value}
        label='Group'
        onClick={() => onChange(APPOINTMENT_GROUP_VALUE.GROUP)}
      />
    </RadioGroupStyled>
  );
};

export default RadioGroup;

const RadioGroupStyled = styled(Row)`
  gap: 24px;
`;

type RadioButtonProps = {
  active?: boolean;
  label: string;
  onClick?: () => void;
};
const RadioButton = ({ active, label, onClick }: RadioButtonProps) => (
  <RadioButtonStyled onClick={onClick}>
    {active ? <RadioActiveIcon /> : <RadioIcon />}
    <Text className="label">{label}</Text>
  </RadioButtonStyled>
);

const RadioButtonStyled = styled.button`
  gap: 8px;
  display: flex;
  align-items: center;
  .label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const RadioActiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
    <rect y="0.5" width="24" height="24" rx="12" stroke="#E5E6EB" />
    <rect x="3.5" y="4" width="17" height="17" rx="8.5" stroke="#F05326" strokeWidth="7" />
  </svg>
);

const RadioIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="4" width="17" height="17" rx="8.5" fill="white" stroke="#CECECE" strokeWidth="7" />
  </svg>
);

import fetch from 'services/request';
import { IRequestBooking } from './types/api';

const getTimeSlot = (currentDate: string, staffId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/getAvailableTimeSlotByStaff/',
    params: { currentDate, staffId },
  });
};

const getAppointmentDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/appointment/getAppointmentById/' + id,
  });
};

const getCustomerDetailByPhone = (phone: string) => {
  return fetch({
    method: 'get',
    // url: `api/v1/customer/signInByPhone/${phone}`,
    url: 'api/v1/customer/getAllCustomerAutoSearch',
    params: {
      keyWord: phone,
    }
  });
};


const bookAppointment = (payload: IRequestBooking) => {
  return fetch({
    url: 'api/v1/appointment/v2/bookingAppointment',
    method: 'post',
    body: {
      type: 'FEEDBACK/DASHBOARD/ON_CREATE_APPOITMENT',
      payload
    },
  });
};

const depositBooking = (amount: number, appointment: IRequestBooking) => {
  return fetch({
    url: '/api/v1/appointment/deposit-booking',
    method: 'post',
    body: { amount, appointment }
  });
};

const transactionDetail = (id: string) => {
  return fetch({
    url: `/api/v1/appointment/transactionDetail/${id}`,
    method: 'post',
  });
};

const aptApis = {
  getTimeSlot,
  getAppointmentDetail,
  getCustomerDetailByPhone,
  bookAppointment,
  depositBooking,
  transactionDetail,
};

export default aptApis;

import React from 'react';
import styled from 'styled-components';
import { IProductUI } from './types';
import hotDealImg from './assets/hot-deal.png';
import cartIcon from './assets/cart-blue.png';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IProductUI;
  onClick?: () => void;
  cartCount?: number;
  onPlus?: () => void;
  onMinus?: () => void;
};
const Product = ({ data, onClick, cartCount, onPlus, onMinus }: Props) => {
  return (
    <Container>
      <button type='button' onClick={onClick} className="image-container">
        <img className='img-banner' src={data?.image || ''} />
        {data.hotDeals && <img src={hotDealImg} alt="" className="hot-deal-icon" />}
      </button>
      <div className="content-container">
        <div className="title-container">
          <div className="top-deal-container">
            {data.discountLabel && <>
              <div className="sale-box"><span>{data.discountLabel}</span></div>
              <span className="top-deal-text">Top deal</span>
            </>}
          </div>
          <div onClick={onClick} className="title-text">{data.title}</div>
        </div>
        <div className="price-container">
          <div className="top-price-container">
            {(data.originalPrice && data.salePrice) && <>
              <span className="origin-price">{formatCurrency(data.originalPrice)}</span>
              <span className="sale-price">{formatCurrency(data.salePrice)}</span>
            </>
            }
            {data.originalPrice && !data.salePrice && <>
              <span className="sale-price single">{formatCurrency(data.originalPrice)}</span>
            </>}
          </div>
          {cartCount ? <>
            <div className="number-container">
              <button type='button' onClick={onMinus} className="minus-container">
                {minusIcon}
              </button>
              <div className="placeholder-container">
                <span>{cartCount}</span>
              </div>
              <button type='button' onClick={onPlus} className="minus-container plus-container">
                {plusIcon}
              </button>
            </div>
          </> : <button type='button' onClick={onPlus} className='cart-btn'><img src={cartIcon} /></button>}

        </div>
      </div>
    </Container>
  );
};

export default Product;
const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap: 12px;
flex: 1 0 0;
align-self: stretch;
background: #FFF;
box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.10);
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  &:active {
    background: #eee;
  }
  img.img-banner {
    width: 140px;
    height: 140px;
  }
  img.hot-deal-icon {
    width: 38px;
    height: 38px;
    position: absolute;
    left: 8px;
    top: 8px;
  }
}

.content-container {
  display: flex;
  padding: 0px 8px 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  flex: 1;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  flex: 1;
  .title-text {
    color: #1D2129;
    align-self: stretch;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.top-deal-container {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  .sale-box {
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #CB2634;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .top-deal-text {
    color: #CB2634;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  .top-price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    span.origin-price {
      color: #1D2129;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; 
      text-decoration: line-through;
      align-self: stretch;
    }
    span.sale-price {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; 
      align-self: stretch;
      &.single {
        font-size: 18px;
      }
    }
  }

  .cart-btn {
    width: 32px;
    height: 32px;
    &:active {
      box-shadow: 0px 0px 0px 3px rgba(0, 113, 133, 0.20);
      border-radius: 4px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.number-container {
  display: flex;
  height: 24px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #1A7EC8;
  background: #FFF;

  .minus-container {
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px 0px 0px 2px;
    &.plus-container {
      border-radius: 0px 2px 2px 0px;
    }
    &:active {
      background: #eee;
      border-radius: 40px;
    }
  }
  .placeholder-container {
    display: flex;
    width: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.32px;
    }
  }
}

@media (min-width:600px)  {
  .image-container {
    img.img-banner {
      width: 316px;
      height: 316px;
    }
    img.hot-deal-icon {
      width: 60px;
      height: 60px;
    }
  }

  .content-container {
    padding: 0px 16px 16px 16px;
  }

  .title-container {
    .title-text {
      font-size: 16px;
    }
  }
  .top-deal-container {
    .sale-box {
      span {
        font-size: 14px;
      }
    }

    .top-deal-text {
      font-size: 14px;
    }
  }

  .price-container {
    .top-price-container {
      .origin-price {
        font-size: 14px;
      }
      .sale-price {
        font-size: 20px;
        &.single {
          font-size: 22px;
        }
      }
    }

    .cart-btn {
      width: 40px;
      height: 40px;
      &:active {
        box-shadow: 0px 0px 0px 3px rgba(0, 113, 133, 0.20);
        border-radius: 4px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .number-container {
    height: 40px;
    .minus-container {
      width: 40px;
      height: 40px;
      svg {
        transform: scale(1.5);
      }
    }

    .placeholder-container {
      span {
        font-size: 18px;
      }
    }
  }
}
`;

const minusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="8" height="2" viewBox="0 0 8 2" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 1.5H0V0.5H8V1.5Z" fill="#1A7EC8" />
</svg>;
const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 4.5H0V3.5H8V4.5Z" fill="#1A7EC8" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3.5 8L3.5 -4.37114e-08L4.5 0L4.5 8L3.5 8Z" fill="#1A7EC8" />
</svg>;

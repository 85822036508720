
import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loading from './animation_spin.json';
import { signal } from '@preact/signals-react';
import styled from 'styled-components';

export function Loading({ small }: { small?: boolean }) {
  return (
    <>
      <Player
        autoplay={true}
        loop={true}
        controls={true}
        src={loading}
        style={small ? { height: '150px', width: '200px' } : { height: '20vh', width: '40vw' }}
      ></Player>
    </>
  );
}

export const LoadingWrapPage = () => <LoadingStyled><Loading /></LoadingStyled>;

export const pageLoading = signal(false);
function PageLoading() {
  if (!pageLoading.value) return null;
  return (
    <LoadingStyled>
      <Loading />
    </LoadingStyled>
  );
}

export default PageLoading;
const LoadingStyled = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.75)
`;
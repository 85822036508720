import React from 'react';
import styled from 'styled-components';
import arrowIcon from '../../assets/arrow.png';
import { BOOKING_STEP, bookingSignal, tabBarRef } from './signal';
import { CONTAINER_BOOKING_WIDTH } from '../../constants';
export const bookingTabBarRef = React.createRef<HTMLDivElement>();
const BookingTabBar = () => {
  const currentTab = bookingSignal.stepActive.value;
  const onChooseTab = (value: BOOKING_STEP) => () => bookingSignal.onChooseTab(value);
  return (
    <Container>
      <TabBar ref={tabBarRef} className='booking-tab-bar hide-scrollbar'>
        <TabItem
          className={'first-item'}
          onClick={onChooseTab(BOOKING_STEP.INFORMATION_STEP)}
          active={currentTab === BOOKING_STEP.INFORMATION_STEP}
          count={1}
          label="CUSTOMER" />
        <div onClick={onChooseTab(BOOKING_STEP.INFORMATION_STEP)}>{arrowImage}</div>
        <TabItem
          onClick={onChooseTab(BOOKING_STEP.SERVICE_STEP)}
          active={currentTab === BOOKING_STEP.SERVICE_STEP}
          count={2}
          label="SERVICES" />
        <div onClick={onChooseTab(BOOKING_STEP.SERVICE_STEP)}>{arrowImage}</div>
        <TabItem
          onClick={onChooseTab(BOOKING_STEP.STAFF_STEP)}
          active={currentTab === BOOKING_STEP.STAFF_STEP}
          count={3} label="STAFF" />
        <div onClick={onChooseTab(BOOKING_STEP.STAFF_STEP)}>{arrowImage}</div>
        <TabItem
          className={'last-item'}
          onClick={onChooseTab(BOOKING_STEP.TIME_STEP)}
          active={currentTab === BOOKING_STEP.TIME_STEP}
          count={4} label="TIME" />
      </TabBar>
    </Container>
  );
};

export default BookingTabBar;
const Container = styled.div`
  background: #E5E6EB;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 2;

  @media (min-width:600px)  {
    top: 0;
    justify-content: center;
    .booking-tab-bar {
      .arrow-icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow-icon-img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .tab-bar-item {
      flex: 1;
      &.first-item {
        padding-left: 0;
      }
      &.last-item {
        padding-right: 0;
      }
      .count-box {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        span {
          font-size: 18px;
        }
      }
      .tab-item-label {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
`;
const TabBar = styled.div`
  max-width: ${CONTAINER_BOOKING_WIDTH}px;
  width: 100%;
  display: flex;
  padding: 8px 0;
  align-items: center;
  overflow-y: auto;
  gap: 4px;
  .arrow-icon-img {
    width: 16px;
    height: 16px;
  }

`;

const arrowImage = <div className="arrow-icon"><img src={arrowIcon} className='arrow-icon-img' /></div>;
type TabItemProps = {
  label: string;
  count: number;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};
const TabItem = ({ className, count, active, label, onClick }: TabItemProps) => {
  return <TabItemStyled onClick={onClick} className={`tab-bar-item ${active ? 'active' : ''} ${className}`}>
    <div className="count-box"><span>{count}</span></div>
    <p className="tab-item-label">{label}</p>
  </TabItemStyled>;
};
const TabItemStyled = styled.button`
display: flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 4px;
  .count-box {
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #FFF;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }

  .tab-item-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

&.tab-bar-item.active {
  .count-box {
    background: #0D446C;
    span {
      color: #FFF;
    }
  }
}
`;
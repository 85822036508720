
import styled from 'styled-components';
import { ContainerFooter, WrapperFooter } from '../BookingPage/FooterActions';
type Props = {
  onNew: () => void;
};
const SuccessFooter = ({ onNew }: Props) => {
  return (
    <WrapperFooter style={{ position: 'fixed' }}>
      <Container>
        <button onClick={onNew} type='button' className='btn btn-submit'><span>BOOK another appointment</span></button>
      </Container>
    </WrapperFooter>
  );
};

export default SuccessFooter;
const Container = styled(ContainerFooter)``;

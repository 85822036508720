import NotFoundPage from 'NotFound';
import ShopWrapperLayout from 'ShopWrapperLayout';
import { ConfigProvider } from 'antd';
import PageLoading from 'components/PageLoading';
import BookingPageV2 from 'features/Booking/v2/pages/BookingPage';
import BookingSuccessPage from 'features/Booking/v2/pages/SuccessPage';
import BookingSuccess from 'features/Booking/BookingSuccess';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store';
import BookingPageReview from 'features/Booking/v2/pages/ReviewPage';
import BookingTransactionDetailV2 from 'features/Booking/v2/pages/TransactionDetail';
import BookingPage from 'features/Booking/BookingPage';
import BookingTransactionDetail from 'features/Booking/TransactionDetail';
import ShoppingDeals from 'features/ShoppingDeals';
import ProductDetailPage from 'features/ShoppingDeals/ProductDetail';
import { HelmetProvider } from 'react-helmet-async';
import CheckOutPage from 'features/ShoppingDeals/CheckOut';
import CustomerInfoPage from 'features/ShoppingDeals/CustomerInfo';
import AddAddressPage from 'features/ShoppingDeals/AddAddress';
import ShoppingLayout from 'features/ShoppingDeals/ShoppingLayout';
import ShoppingTransactionDetailPage from 'features/ShoppingDeals/TransactionDetail';
import CouponDetailPage from 'features/Coupon/CouponDetail';

const AppLayout: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/:shop_id' element={<ShopWrapperLayout />} >
          <Route path='' element={<BookingPage />} />
          <Route path='success' element={<BookingSuccess />} />
          <Route path='payment-detail/:transaction_id' element={<BookingTransactionDetail />} />
          <Route path='coupon/:coupon_id' element={<CouponDetailPage />} />
        </Route>
        <Route path='/v2/:shop_id' element={<ShopWrapperLayout />} >
          <Route path='' element={<BookingPageV2 />} />
          <Route path='review' element={<BookingPageReview />} />
          <Route path='success' element={<BookingSuccessPage />} />
          <Route path='payment-detail/:transaction_id' element={<BookingTransactionDetailV2 />} />
        </Route>
        <Route path='/:shop_id/shopping' element={<ShoppingLayout />}>
          <Route path='' element={<ShoppingDeals />} />
          <Route path=':id' element={<ProductDetailPage />} />
          <Route path='customer-info' element={<CustomerInfoPage />} />
          <Route path='check-out' element={<CheckOutPage />} />
          <Route path='add-address' element={<AddAddressPage />} />
          <Route path='payment-detail/:transaction_id' element={<ShoppingTransactionDetailPage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

const App: React.FC = () => (
  <HelmetProvider>
    <ConfigProvider
      theme={{
        'token': {
          'colorPrimary': '#F05326',
          'colorPrimaryBg': '#FFF1F6',
          'colorPrimaryActive': '#F05326',
          'colorText': '#1D2129',
          'colorTextSecondary': '#767676',
          'colorBorder': '#86909C',
          'colorBorderSecondary': '#CECECE',
          'colorFill': '#E5E6EB',
          'colorFillSecondary': '#E5E5E5',
          'colorFillTertiary': '#F6F7FC',
          'colorBgLayout': '#E5E5E5',
          'colorBgSpotlight': '#1D2129',
          'colorBgMask': 'rgba(0, 0, 0, 0.2)',
          'colorTextBase': '#1d2129',
          'colorBgBase': '#ffffff',
          'colorWarning': '#ff7d00',
          'colorError': '#f53f3f',
          'colorInfo': '#6fabb6',
          'fontSize': 16,
          'sizeStep': 4,
          'borderRadius': 5,
          'borderRadiusXS': 2,
          'wireframe': false
        },
      }}
    >
      <Provider store={store}>
        <AppLayout />
        <ToastContainer />
        <PageLoading />
      </Provider>
    </ConfigProvider>
  </HelmetProvider>
);

export default App;

import styled from 'styled-components';
import Icon from 'assets/Icons';
import Text from 'components/Text';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
  minValue?: number;
  maxValue?: number;
};
const GroupConfigure = ({ value = 0, minValue = 0, maxValue, onChange = () => undefined }: Props) => {
  const decrease = () => {
    const nextValue = value - 1;
    if (nextValue < minValue) return;
    onChange(nextValue);
  };
  const increase = () => {
    const nextValue = value + 1;
    if (maxValue && nextValue > maxValue) return;
    onChange(nextValue);
  };
  return (
    <Wrapper>
      <button onClick={e => [e.preventDefault(), decrease()]}>
        <Icon type='minus' />
      </button>
      <Text className='group-text-size' variant='BODY_2' style={{ width: '24px', textAlign: 'center' }}>{value}</Text>
      <button onClick={e => [e.preventDefault(), increase()]}>
        <Icon type='plus' />
      </button>
    </Wrapper>
  );
};

export default GroupConfigure;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
import { ContainerFooter, WrapperFooter } from 'features/Booking/v2/pages/BookingPage/FooterActions';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import TabBar from '../TabBar';
import { Checkbox, Form, Input } from 'antd';
import PhoneInput from 'components/PhoneInput';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import shoppingSelectors from '../services/selectors';
import { formatPhone } from 'utils/formatPhone';
import { useAppDispatch } from 'store/hooks';
import { shoppingUIActions } from '../services/reducers';
import storage from 'utils/sessionStorage';
export const useGoAddAddress = () => {
  const navigate = useNavigate();
  return (shopId: string, change?: boolean) => {
    navigate(`/${shopId}/shopping/add-address`, { state: { change } });
  };
};

const AddAddressPage = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const _location = useLocation();
  const customerInfo = shoppingSelectors.customerInfo();
  const address = shoppingSelectors.address();
  const isUpdate = _location.state?.change || false;

  const onBack = () => navigate(`/${shop_id}/shopping/check-out`);

  useEffect(() => {
    if (isUpdate && address) {
      form.setFieldsValue(address);
      return;
    }
    form.setFieldsValue({
      customerName: customerInfo?.name || '',
      customerPhone: formatPhone(customerInfo?.phone || ''),
    });
    const addressStore = storage.address.get();
    if (addressStore) {
      const [address = '', city = '', state = '', zip = ''] = addressStore.split('|');
      form.setFieldsValue({ address, city, state, zip });
    }
  }, []);

  const onFinish = (values: any) => {
    if (values.markDefault) {
      storage.address.set([values.address, values.city, values.state, values.zip].join('|'));
    }

    dispatch(shoppingUIActions.setAddressData(values));
    onBack();
  };

  return (
    <Wrapper>
      <TabBar onBack={onBack} disableCart title='Delivery Address' />
      <Container>
        <Content layout='vertical' form={form} onFinish={onFinish} initialValues={{ markDefault: false }}>
          <div className="section-container">
            <p className="section-title">Customer Information</p>
            <div className="information-form-container">
              <Form.Item label='Full Name' name={'customerName'} rules={[{ required: true, message: 'Please enter' }]}>
                <Input placeholder='Full Name' disabled />
              </Form.Item>
              <Form.Item label='Phone Number' name='customerPhone' rules={[{ required: true, message: 'Please enter' }]}>
                <PhoneInput disabled />
              </Form.Item>
            </div>
          </div>
          <div className="section-container">
            <p className="section-title">Address</p>
            <div className="information-form-container">
              <Form.Item label='Address' name={'address'} rules={[{ required: true, message: 'Please enter' }]}>
                <Input placeholder='Please enter...' />
              </Form.Item>
              <Form.Item label='City' name='city' rules={[{ required: true, message: 'Please enter' }]}>
                <Input placeholder='Select' />
              </Form.Item>
              <Form.Item label='State' name='state' rules={[{ required: true, message: 'Please enter' }]}>
                <Input placeholder='Select' />
              </Form.Item>
              <Form.Item label='Zip Code' name='zip' rules={[{ required: true, message: 'Please enter' }]}>
                <Input placeholder='Please enter...' />
              </Form.Item>
              <div className="checkbox-container">
                <Form.Item noStyle name={'markDefault'} valuePropName="checked">
                  <Checkbox>Make this my default address</Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
        </Content>
      </Container>
      <WrapperFooter>
        <FooterContainer style={{ maxWidth: 'unset' }}>
          <button onClick={() => form.submit()} type='button' className='btn btn-submit'><span>{isUpdate ? 'Update' : 'Add'} delivery address</span></button>
        </FooterContainer>
      </WrapperFooter>
    </Wrapper >
  );
};

export default AddAddressPage;

const Content = styled(Form)`
  margin-bottom: 32px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-top: 8px;
  background: #F7F8FA;
  .section-container {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: #FFF;
    .ant-form-item {
      align-self: stretch;

      .ant-form-item-label label {
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .phone-input, .ant-input {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .section-title {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }

  .information-form-container {
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    .ant-input, .phone-input {
      height: 40px;
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-checkbox-wrapper span{
      color: #5C6D8A;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }
    
  @media (min-width:600px) {
    .information-form-container {
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
    }
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
flex: 1;
position: relative;

.scroll-view {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}
`;
const FooterContainer = styled(ContainerFooter)`
flex-direction: column;
align-items: center;
justify-content: center;
gap: 12px;

button.btn {
  width: 100%;
}

@media (min-width:600px) {
  padding-right: 32px;
  padding-left: 32px;
}
`;
import { Image } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import banner from '../../assets/promotionBanner.png';
import shopSelectors from 'services/shop/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import moment from 'moment';

const BookingPromotion = () => {
  const promotion = shopSelectors.promotion();

  const time = useMemo(() => {
    if (!promotion) return null;

    const startDate = moment(promotion.startDate, 'MM-DD-YYYY HH:mm:ss');
    const endDate = moment(promotion.endDate, 'MM-DD-YYYY HH:mm:ss');

    const secondDiff = endDate.diff(startDate, 'second');
    const totalHour = (secondDiff / 3600);
    const totalDay = totalHour / 24;
    const day = totalDay - totalDay % 1;
    const remainingHour = (totalDay % 1) * 24;
    const decimalHour = remainingHour % 1;
    const hour = remainingHour - decimalHour;
    const remainMinute = decimalHour * 60;
    const decimalMinute = remainMinute % 1;
    const minute = remainMinute - decimalMinute;
    const second = Math.round(decimalMinute * 60);
    return ({ day, hour, minute, second });
  }, [promotion]);

  if (!promotion) return null;
  return (
    <Container>
      <Image className='banner' width={'100%'} preview={false} src={banner} />
      <div className="promotion-content">
        <div>
          <p className="promotion-title">Book your appointment now</p>
          <p className="promotion-title">& save <span>{promotion.promotionType === 'PERCENT' ? `${promotion.promotionValue}%` : formatCurrency(promotion.promotionValue)} OFF</span> on all spa services.</p>
        </div>
        <CountdownContainer>
          <div className="countdown-item">
            <div className="countdown-box">
              <span>{time?.day || 0}</span>
              <div className="divider"></div>
            </div>
            <span className="countdown-label">days</span>
          </div>

          <div className="countdown-item countdown-inspector">
            <div className="countdown-box">
              <span>:</span>
            </div>
          </div>
          <div className="countdown-item">
            <div className="countdown-box">
              <span>{time?.hour || 0}</span>
              <div className="divider"></div>
            </div>
            <span className="countdown-label">hours</span>
          </div>

          <div className="countdown-item countdown-inspector">
            <div className="countdown-box">
              <span>:</span>
            </div>
          </div>

          <div className="countdown-item">
            <div className="countdown-box">
              <span>{time?.minute || 0}</span>
              <div className="divider"></div>
            </div>
            <span className="countdown-label">mins</span>
          </div>
        </CountdownContainer>
      </div>
    </Container>
  );
};

export default BookingPromotion;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .countdown-box {
      position: relative;
      display: flex;
      width: 40px;
      height: 35px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 1.304px;
      background: #FFF;
      span {
        color: #F05326;
        text-align: center;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .divider {
        height: 1px;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: #1D2129;
      }
    } 

    .countdown-label {
      color: #FFF;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.countdown-inspector {
      .countdown-box {
        width: auto;
        background: transparent;
        span {
          color: #fff;
        }
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  .promotion-content {
    position: absolute;
    inset: 0;
    z-index: 1;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
  .ant-image-img.banner {
    height: 130px;
  }
  .promotion-title {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 27.5px */
    span {
      font-size: 20px;
      font-weight: 700;
    }
  }

  @media (min-width:600px)  {
    .ant-image-img.banner {
      height: 210px;
    }
    .promotion-title {
      font-size: 20px;
      span {
        font-size: 32px;
      }    
    }

    .countdown-item {
      .countdown-box {
        height: 70px;
        width: 70px;
        border-radius: 3px;
        span {
          font-size: 40px;
        }
        .divider {
          height: 2px;
        }
      }

      .countdown-label {
        font-size: 24px;
      }
    }
  }
`;
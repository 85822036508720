
import { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { ICouponDetailResData } from './types/coupon';

const getCouponDetail: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: ICouponDetailResData }> = yield call(apis.getCouponDetail, payload);
    if (res.data.payload) {
      yield put(actions.getCouponDetail.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getCouponDetail.fail({}));
  }
};

export default function* couponServiceSagas() {
  yield takeLatest(actions.getCouponDetail.fetch, getCouponDetail);
}

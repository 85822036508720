import { memo } from 'react';
import styled from 'styled-components';
import BookingDatePicker from './DatePicker';
import BookingTimeSlotList, { bookingTimeSlotListRef } from './TimeSlotList';

const TimeTab = () => {
  return (
    <Container>
      <p className='section-title'>Date</p>
      <BookingDatePicker />
      <p style={{ marginTop: 16 }} className='section-title'>Time</p>
      <BookingTimeSlotList ref={bookingTimeSlotListRef} />
    </Container>
  );
};

export default memo(TimeTab);

const Container = styled.div`
  padding-bottom: 16px;
`;
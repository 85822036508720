import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

type MyState = RootState['coupon'];
const getCurrentState = (state: RootState): MyState => state['coupon'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const couponDetail = {
  state: () => selector('couponDetail') as MyState['couponDetail'],
  data: () => selector('couponDetail.data') as MyState['couponDetail']['data'],
  loading: () => selector('couponDetail.loading') as MyState['couponDetail']['loading'],
};

const couponSelectors = {
  detail: couponDetail,
};
export default couponSelectors;

import { Form, Input, InputRef, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import PhoneInput from 'components/PhoneInput';
import Text from 'components/Text';
import { bookingSignal } from 'features/Booking/BookingPage/signal';
import aptApis from 'features/Booking/services/apis';
import { ICustomerDetailInfo } from 'features/Booking/services/types/customer';
import { debounce, first } from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TopBar from '../../TopBar';
import StepCount from '../StepCount';
import shopSelectors from 'services/shop/selectors';
import CouponDiscount from './template/Discount';
import { ICouponResItem } from 'types/response';
type IFormValues = {
  name: string;
  phone: string;
  coupon: string;
};
const InformationStep = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState<ICustomerDetailInfo | null>(null);
  const phoneNumberRef = useRef<InputRef>(null);
  const nameRef = useRef<InputRef>(null);
  const couponData = shopSelectors.couponData();
  const [coupon, setCoupon] = useState<string>('');
  const handleFinish = (values: IFormValues) => {
    bookingSignal.customerInfo.value = {
      id: customerInfo?.id?.toString() || '',
      name: values.name,
      phone: values.phone,
      coupon: values.coupon,
    };
    bookingSignal.next();
  };

  useEffect(() => {
    setTimeout(() => phoneNumberRef.current?.focus(), 1000);
  }, []);

  const InputPhoneChange = useCallback(debounce<React.ChangeEventHandler<HTMLInputElement>>(async (e) => {
    const raw = e.target.value?.replace(/[^0-9]/g, '');
    if (raw.length !== 10) return;
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: ICustomerDetailInfo[] }> = await aptApis.getCustomerDetailByPhone(raw);
      const firstData = first(res.data.payload);
      if (firstData) {
        setCustomerInfo(firstData);
        form.setFieldValue('name', firstData.name);
        form.validateFields();
      } else {
        nameRef.current?.focus();
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  const handleChooseCoupon = (o: ICouponResItem) => {
    const couponCode = o.couponCode?.toString();
    setCoupon(couponCode);
    form.setFieldValue('coupon', couponCode);
  };

  return (
    <>
      <TopBar onNext={() => form.submit()} />
      <StepCount />
      <Wrapper>
        <Form form={form} onFinish={handleFinish}>
          <Spin spinning={loading}>
            <Form.Item
              label={<Row><Text className='label-form'>Phone Number <span>(*)</span> </Text></Row>}
              name={'phone'}
              rules={[{ required: true, message: 'Please enter your phone number' }]}
            >
              <PhoneInput autoFocus onChange={InputPhoneChange} />
            </Form.Item>
            <Form.Item
              label={<Row><Text className='label-form'>Your Name <span>(*)</span> </Text></Row>}
              name={'name'}
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input
                ref={nameRef}
                placeholder='Please enter...'
              />
            </Form.Item>
          </Spin>
          <>
            <Form.Item
              label={<Row><Text className='label-form'>Coupon </Text></Row>}
              name={'coupon'}
            >
              <Input
                disabled
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder='Please enter coupon code...'
              />
            </Form.Item>
            <CouponDiscount
              data={couponData}
              onChooseCoupon={handleChooseCoupon}
              activeId={coupon}
            />
          </>
        </Form>
      </Wrapper>
    </>

  );
};

export default memo(InformationStep);

const Wrapper = styled.div`
  padding: 0 1rem;
  .label-form {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #F05326;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
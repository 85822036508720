
import { Form, FormInstance, Row } from 'antd';
import styled from 'styled-components';
import { IDepositSetting } from 'types/deposit';
import { formatCurrency } from 'utils/formatCurrency';
import { ContainerFooter, WrapperFooter } from '../BookingPage/FooterActions';
type Props = {
  onBooking?: () => void;
  onPayment?: () => void;
  depositSetting: IDepositSetting | null;
  formDeposit: FormInstance<{ amount: number }>
};
const ReviewFooter = ({ onBooking, onPayment, depositSetting, formDeposit }: Props) => {
  const amount = Form.useWatch('amount', formDeposit);

  return (
    <WrapperFooter style={{ position: 'fixed' }}>
      <Container>
        {depositSetting &&
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <div className="partial-text">Partial amount</div>
            <div className="partial-value">{formatCurrency(amount)}</div>
          </Row>}
        {depositSetting ?
          <button onClick={onPayment} type='button' className='btn btn-submit'><span>PAY</span></button> :
          <button onClick={onBooking} type='button' className='btn btn-submit'><span>BOOK</span></button>
        }
      </Container>
    </WrapperFooter>
  );
};

export default ReviewFooter;
const Container = styled(ContainerFooter)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  button.btn {
    width: 100%;
  }

  .partial-text {
    color: #5C6D8A;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .partial-value {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (min-width:600px)  {
    .partial-text {
      font-size: 18px;
    }
    .partial-value {
      font-size: 24px;
    }
  }
`;

import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
// import { useSocketContext } from 'services/useSocket';

function ShopWrapperLayout() {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const shopInfo = shopSelectors.info();
  // const socket = useSocketContext();
  // const initConnectSocket = debounce(() => socket.connect(shop_id), 100);
  useEffect(() => {
    if (!shop_id) return;
    dispatch(shopActions.doInit(shop_id));
  }, [shop_id]);

  if (!shopInfo) return null;

  return (
    <>
      <Outlet />
    </>
  );
}

export default ShopWrapperLayout;

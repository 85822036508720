import { memo } from 'react';
import styled from 'styled-components';
import StepCount from '../StepCount';
import CalendarView from './DateFeature/Calendar';
import StaffFeature from './StaffFeature';
import TopBar from '../../TopBar';
import { bookingSignal } from 'features/Booking/BookingPage/signal';

const DateAndStaffStep = () => {
  const handleNext = () => {
    if (!bookingSignal.staffId.value) return;
    bookingSignal.next();
  };
  return (
    <>
      <TopBar onBack={bookingSignal.back} onNext={handleNext} />
      <StepCount />
      <Wrapper>
        <CalendarView />
        <StaffFeature />
      </Wrapper>
    </>
  );
};

export default memo(DateAndStaffStep);

const Wrapper = styled.div`
  padding: 0 1rem;
  .label-form {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #F05326;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
